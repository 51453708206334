
  import { defineComponent, onMounted, Ref, ref } from 'vue';
  import firebase from 'firebase';
  import useToast from '@/composables/use-toast';
  import { useI18n } from 'vue-i18n';
  import { v4 as uuidv4 } from 'uuid';

  import { FunTeamBiddingActiveQuestion } from '@/interfaces/funteambidding';

  // import {
  //   GENERAL_KNOWLEDGE,
  //   FILM,
  //   MUSIC,
  //   SCIENCE_NATURE,
  //   SCIENCE_COMPUTERS,
  //   MYTHOLOGY,
  //   SPORTS,
  //   GEOGRAPHY,
  //   HISTORY,
  //   POLITICS,
  //   ANIMALS
  // } from '@/questions';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'SuperUserFunTeamBiddingQuestions',
    setup() {
      const questions: Ref = ref([]);
      const { errorToast, successToast } = useToast();
      const { t } = useI18n();

      onMounted(async () => {
        try {
          questions.value = await getAllFunTeamBiddingQuestions();
        } catch {
          errorToast('Something went wrong. Please reload the page.');
        }
      });

      const typeOptions: Ref<{ value: string; label: string }[]> = ref([
        {
          value: 'multiple',
          label: t('funTeamBidding.customQuestions.multiple')
        },
        {
          value: 'yesNo',
          label: t('funTeamBidding.customQuestions.yesNo')
        }
      ]);

      const getAllFunTeamBiddingQuestions = async () => {
        return await db
          .collection('funTeamBiddingQuestions')
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push({ id: doc.id, ...doc.data() });
            });

            return resultArray;
          })
          .catch(e => {
            errorToast(e);
          });
      };

      const handleSaveQuestions = async (questions: any, type: any) => {
        try {
          await db.collection('funTeamBiddingQuestions').doc(type).set({
            list: questions
          });
          successToast(t('success.saved'));
        } catch (e) {
          errorToast(e);
        }
      };

      // const handleDeleteQuestionAndSave = async (id: number) => {
      //   questions.value.list = questions.value.list.filter(
      //     (q: FunTeamBiddingActiveQuestion) => q.id !== id
      //   );
      //
      //   await handleSaveQuestions();
      // };

      const addNew = (category: string) => {
        const indexOfList = questions.value.findIndex((list: any) => list.id === category);

        const payload: FunTeamBiddingActiveQuestion = {
          id: uuidv4(),
          category,
          textPl: '',
          textEn: '',
          type: 'multiple',
          options: ['', '', '', ''],
          validAnswer: ''
        };

        questions.value = [
          ...questions.value.slice(0, indexOfList),
          {
            ...questions.value[indexOfList],
            list: [payload, ...questions.value[indexOfList].list]
          },
          ...questions.value.slice(indexOfList + 1)
        ];
      };

      return {
        questions,
        // handleDeleteQuestionAndSave,
        addNew,
        handleSaveQuestions,
        typeOptions
        // GENERAL_KNOWLEDGE,
        // FILM,
        // MUSIC,
        // SCIENCE_NATURE,
        // SCIENCE_COMPUTERS,
        // MYTHOLOGY,
        // SPORTS,
        // GEOGRAPHY,
        // HISTORY,
        // POLITICS,
        // ANIMALS
      };
    }
  });
