
  import { computed, ComputedRef, defineComponent, onMounted, Ref, ref } from 'vue';
  import firebase from 'firebase';
  import { useI18n } from 'vue-i18n';
  import { v4 as uuidv4 } from 'uuid';

  import useToast from '@/composables/use-toast';
  import { useConfirm } from 'primevue/useconfirm';
  import { CardUpgradeConfig, ChanceCard } from '@/interfaces/businessgame';
  import BaseTooltipWrapper from '@/components/common/BaseTooltipWrapper.vue';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'SuperUserFunBusinessGameChance',
    components: { BaseTooltipWrapper },
    setup() {
      const { successToast, errorToast } = useToast();
      const { t, locale } = useI18n();
      const confirm = useConfirm();

      const configs: Ref<any> = ref([]);

      const chanceCardsChangedActiveIndex: Ref<number> = ref(0);
      const chanceCards: ComputedRef<ChanceCard[]> = computed(() => {
        // @ts-ignore
        return configs.value[chanceCardsChangedActiveIndex.value]?.cards;
      });

      const upgradeConfig: Ref<CardUpgradeConfig> = computed(() => ({
        categoryOne: {
          en: 'categoryOne',
          pl: 'categoryOne'
        },
        categoryTwo: {
          en: 'categoryTwo',
          pl: 'categoryTwo'
        },
        categoryThree: {
          en: 'categoryThree',
          pl: 'categoryThree'
        },
        categoryFour: {
          en: 'categoryFour',
          pl: 'categoryFour'
        }
      }));

      const cardEditedIndex: Ref<number> = ref(0);

      const cardEdited: ComputedRef<ChanceCard> = computed(() => {
        return chanceCards.value?.[cardEditedIndex.value];
      });

      onMounted(async () => {
        try {
          configs.value = await getAllChanceCardConfigs();
        } catch {
          errorToast('Something went wrong. Please reload the page.');
        }
      });

      const methodOptions: Ref<{ value: string; label: string }[]> = ref([
        {
          value: 'addRoundToCurrentPlayer',
          label: t('adminPanel.businessGame.chanceCards.actionsLabels.addRoundToCurrentPlayer')
        },
        {
          value: 'addMoneyToCurrentPlayer',
          label: t('adminPanel.businessGame.chanceCards.actionsLabels.addMoneyToCurrentPlayer')
        },
        {
          value: 'removeMoneyFromCurrentPlayer',
          label: t('adminPanel.businessGame.chanceCards.actionsLabels.removeMoneyFromCurrentPlayer')
        },
        {
          value: 'addToPayroll',
          label: t('adminPanel.businessGame.chanceCards.actionsLabels.addToPayroll')
        },
        {
          value: 'removeFromPayroll',
          label: t('adminPanel.businessGame.chanceCards.actionsLabels.removeFromPayroll')
        },
        {
          value: 'removeMoneyFromCurrentPlayerIfConditions',
          label: t(
            'adminPanel.businessGame.chanceCards.actionsLabels.removeMoneyFromCurrentPlayerIfConditions'
          )
        },
        {
          value: 'addMoneyToPayrollIfConditions',
          label: t(
            'adminPanel.businessGame.chanceCards.actionsLabels.addMoneyToPayrollIfConditions'
          )
        },
        {
          value: 'addMoneyToCurrentPlayerIfConditions',
          label: t(
            'adminPanel.businessGame.chanceCards.actionsLabels.addMoneyToCurrentPlayerIfConditions'
          )
        },
        {
          value: 'changeTeamMembers',
          label: t('adminPanel.businessGame.chanceCards.actionsLabels.changeTeamMembers')
        }
      ]);

      const fromToOptions: Ref<{ value: string; label: string }[]> = computed(() => {
        return [
          {
            value: 'categoryOne',
            // @ts-ignore
            label: upgradeConfig.value?.categoryOne[locale.value]
          },
          {
            value: 'categoryTwo',
            // @ts-ignore
            label: upgradeConfig.value?.categoryTwo[locale.value]
          },
          {
            value: 'categoryThree',
            // @ts-ignore
            label: upgradeConfig.value?.categoryThree[locale.value]
          },
          {
            value: 'categoryFour',
            // @ts-ignore
            label: upgradeConfig.value?.categoryFour[locale.value]
          }
        ];
      });

      const typeOptions: Ref<{ value: string; label: string }[]> = computed(() => {
        return [
          // {
          //   value: 'teamFieldMatch',
          //   label: t(
          //     'adminPanel.businessGame.chanceCards.typeOptions.teamFieldMatch'
          //   )
          // },
          // {
          //   value: 'teamFieldDontMatch',
          //   label: t(
          //     'adminPanel.businessGame.chanceCards.typeOptions.teamFieldDontMatch'
          //   )
          // },
          {
            value: 'cardsMax',
            label: t('adminPanel.businessGame.chanceCards.typeOptions.cardsMax')
          }
        ];
      });

      const whatToShowConfig: Ref = ref({
        addMoneyToCurrentPlayer: 'number',
        addRoundToCurrentPlayer: 'number',
        removeMoneyFromCurrentPlayer: 'number',
        addToPayroll: 'number',
        removeFromPayroll: 'number',
        removeMoneyFromCurrentPlayerIfConditions: 'conditions',
        addMoneyToPayrollIfConditions: 'conditions',
        addMoneyToCurrentPlayerIfConditions: 'conditions',
        changeTeamMembers: 'fromToMax'
      });

      const editCard = (id: string, configIndex: number) => {
        chanceCardsChangedActiveIndex.value = configIndex;
        cardEditedIndex.value = chanceCards.value.findIndex((c: ChanceCard) => c.id === id);
      };

      const addNew = (configIndex: number) => {
        const id = uuidv4();
        // @ts-ignore
        configs.value[configIndex].cards = [
          {
            id,
            type: 'chance',
            descriptionEn: '',
            descriptionPl: ''
          },
          // @ts-ignore
          ...configs.value[configIndex].cards
        ];

        editCard(id, configIndex);
      };

      const deleteChanceCard = (id: string, configIndex: number, configId: string) => {
        confirm.require({
          message: t('adminPanel.businessGame.chanceCards.wantToProceed'),
          header: t('adminPanel.businessGame.chanceCards.confirmation'),
          icon: 'pi pi-exclamation-triangle',
          accept: async () => {
            try {
              // @ts-ignore
              const cards = configs.value[configIndex].cards.filter(
                (card: ChanceCard) => card.id !== id
              );
              await db.doc(`chanceCardsConfigs/${configId}`).update({
                cards
              });

              // eslint-disable-next-line require-atomic-updates
              configs.value[configIndex] = { cards, id: configs.value[configIndex].id };
              successToast(t('adminPanel.businessGame.chanceCards.removed'));
            } catch (e) {
              errorToast(e);
            }
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        });
      };

      const changeCardEditedMethod = (value: string, configIndex: number) => {
        let payload: any = null;

        if (whatToShowConfig.value[value] === 'number') {
          payload = null;
        } else if (whatToShowConfig.value[value] === 'conditions') {
          payload = {
            type: null,
            value: null,
            money: null
          };
        } else if (whatToShowConfig.value[value] === 'fromToMax') {
          payload = {
            from: null,
            to: null,
            max: null
          };
        }

        // @ts-ignore
        const cardIndex = configs.value[configIndex].cards.findIndex(
          (card: ChanceCard) => card.id === cardEdited.value.id
        );

        // @ts-ignore
        configs.value[configIndex].cards = [
          // @ts-ignore
          ...configs.value[configIndex].cards.slice(0, cardIndex),
          {
            // @ts-ignore
            ...configs.value[configIndex].cards[cardIndex],
            method: value,
            payload
          },
          // @ts-ignore
          ...configs.value[configIndex].cards.slice(cardIndex + 1)
        ];
      };

      const handleSaveChanceCards = async (configIndex: number, configId: string) => {
        try {
          await db.doc(`chanceCardsConfigs/${configId}`).update({
            // @ts-ignore
            cards: configs.value[configIndex].cards
          });
          successToast(t('adminPanel.businessGame.chanceCards.updated'));
        } catch (e) {
          errorToast(e);
        }
      };

      const getAllChanceCardConfigs = async () => {
        return await db
          .collection('chanceCardsConfigs')
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push({ id: doc.id, ...doc.data() });
            });

            return resultArray;
          })
          .catch(e => {
            errorToast(e);
          });
      };

      const addNewChanceConfig = async () => {
        try {
          await db.doc(`chanceCardsConfigs/fintech`).set({ cards: configs.value[2].cards });
          successToast(t('adminPanel.businessGame.chanceCards.updated'));
        } catch (e) {
          errorToast(e);
        }
      };

      return {
        configs,
        cardEdited,
        methodOptions,
        fromToOptions,
        typeOptions,
        whatToShowConfig,
        chanceCardsChangedActiveIndex,
        addNew,
        editCard,
        deleteChanceCard,
        addNewChanceConfig,
        changeCardEditedMethod,
        handleSaveChanceCards
      };
    }
  });
