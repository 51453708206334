
  import firebase from 'firebase';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { ref, Ref, defineComponent } from 'vue';

  import { defaultAvatars } from '@/constants/avatars';
  import { emptyFunIceBreakerSettings } from '@/helpers/funicebreaker/initials';
  import { emptyFunTeamBiddingSettings } from '@/helpers/funteambidding/initials';
  import useToast from '@/composables/use-toast';
  import { GAME_NAMES } from '@/helpers';
  import RegisterFormOnly from '@/components/common/RegisterFormOnly.vue';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'SuperUserCreateCompanyManually',
    components: { RegisterFormOnly },
    setup() {
      const { currentRoute } = useRouter();
      const { errorToast } = useToast();
      const { t } = useI18n();

      const noOfGames: Ref<string> = ref('');
      const loading: Ref<string> = ref('');

      const getBoardConfig = async (configName: string) => {
        return await db
          .doc(`boardConfigs/${configName}`)
          .get()
          .then(doc => doc.data())
          .catch(e => {
            throw new Error(e);
          });
      };

      const getChanceCards = async (configName: string) => {
        return await db
          .doc(`chanceCardsConfigs/${configName}`)
          .get()
          .then(doc => doc.data())
          .catch(e => {
            throw new Error(e);
          });
      };

      const getFunIceBreakerQuestions = async () => {
        return await db
          .doc('funIceBreakerQuestions/default')
          .get()
          .then(doc => doc.data())
          .catch(e => {
            throw new Error(e);
          });
      };

      const submit = async (registerData: any) => {
        try {
          loading.value = t('checkout.withRegister.downloadingBoard');

          const boardConfig = await getBoardConfig(registerData.boardTemplate);
          const chanceCards = await getChanceCards(registerData.chanceTemplate);
          const funIceBreakerQuestions = await getFunIceBreakerQuestions();

          loading.value = t('checkout.withRegister.creatingUser');

          await firebase
            .auth()
            .createUserWithEmailAndPassword(registerData.email, registerData.password)
            .catch(e => {
              throw new Error(e);
            });

          loading.value = t('checkout.withRegister.creatingCompany');

          const companyId = await db
            .collection('companies')
            .add({
              email: registerData.email,
              companyName: registerData.companyName,
              currency: 'PLN',
              signupLanguage: 'en',
              firstPurchase: true,
              avatars: defaultAvatars,
              boardConfig: boardConfig?.config,
              brandColor: '#3061AC',
              chanceCards: chanceCards?.cards,
              customBoardConfigTemplates: [],
              customChanceCardsTemplates: [],
              funIceBreakerSettings: emptyFunIceBreakerSettings,
              funIceBreakerCustomQuestions: funIceBreakerQuestions?.list,
              funTeamBiddingSettings: emptyFunTeamBiddingSettings,
              funTeamBiddingCustomQuestions: [],
              funTeamBiddingPresets: [],
              logo: null,
              name: registerData.companyName,
              package: {
                packageId: null,
                gamesInPackage: {
                  [GAME_NAMES.FUN_TEAM_BIDDING]: Number(noOfGames.value),
                  [GAME_NAMES.FUN_BUSINESS_GAME]: Number(noOfGames.value),
                  [GAME_NAMES.FUN_ICE_BREAKER]: Number(noOfGames.value)
                },
                gamesLeft: {
                  [GAME_NAMES.FUN_TEAM_BIDDING]: Number(noOfGames.value),
                  [GAME_NAMES.FUN_BUSINESS_GAME]: Number(noOfGames.value),
                  [GAME_NAMES.FUN_ICE_BREAKER]: Number(noOfGames.value)
                },
                price: 0,
                currency: 'PLN',
                mode: 'payment',
                unlimited: true,
                boughtOn: Date.now(),
                endsOn: null
              },
              referralCode: Math.random().toString(36).substr(2, 10),
              referralCodeUsedDuringRegistration: registerData.referralCode,
              referralData: {
                balance: 0,
                referredTo: [],
                historyOfUsedBalance: []
              },
              historyOfPurchases: [],
              upgradeConfig: boardConfig?.upgradeConfig,
              userEmails: [registerData.email]
            })
            .then(doc => {
              return doc.id;
            })
            .catch(e => {
              errorToast(t('checkout.withRegister.errorAddingDocument'));
              throw new Error(e);
            });

          await db
            .collection(`companies/${companyId}/users`)
            .doc(registerData.email)
            .set({
              email: registerData.email,
              pending: false,
              roles: {
                admin: true,
                gameCreator: true
              }
            })
            .catch(e => {
              throw new Error(e);
            });

          loading.value = '';
        } catch (e) {
          loading.value = '';
          errorToast(e.message ? e.message : e);
        }
      };

      return { submit, loading, noOfGames };
    }
  });
