export default [
  {
    name: 'Andorra',
    code: {
      label: 'Andorra',
      value: 'AD'
    }
  },
  {
    name: 'Austria',
    code: {
      label: 'Austria',
      value: 'AT'
    }
  },
  {
    name: 'Belgium',
    code: {
      label: 'Belgium',
      value: 'BE'
    }
  },
  {
    name: 'Bosnia and Herzegovina',
    code: {
      label: 'Bosnia and Herzegovina',
      value: 'BA'
    }
  },
  {
    name: 'Bulgaria',
    code: {
      label: 'Bulgaria',
      value: 'BG'
    }
  },
  {
    name: 'Croatia',
    code: {
      label: 'Croatia',
      value: 'HR'
    }
  },
  {
    name: 'Cyprus',
    code: {
      label: 'Cyprus',
      value: 'CY'
    }
  },
  {
    name: 'Czech Republic',
    code: {
      label: 'Czech Republic',
      value: 'CZ'
    }
  },
  {
    name: 'Denmark',
    code: {
      label: 'Denmark',
      value: 'DK'
    }
  },
  {
    name: 'Estonia',
    code: {
      label: 'Estonia',
      value: 'EE'
    }
  },
  {
    name: 'Finland',
    code: {
      label: 'Finland',
      value: 'FI'
    }
  },
  {
    name: 'France',
    code: {
      label: 'France',
      value: 'FR'
    }
  },
  {
    name: 'Georgia',
    code: {
      label: 'Georgia',
      value: 'GE'
    }
  },
  {
    name: 'Germany',
    code: {
      label: 'Germany',
      value: 'DE'
    }
  },
  {
    name: 'Gibraltar',
    code: {
      label: 'Gibraltar',
      value: 'GI'
    }
  },
  {
    name: 'Greece',
    code: {
      label: 'Greece',
      value: 'GR'
    }
  },
  {
    name: 'Greenland',
    code: {
      label: 'Greenland',
      value: 'GL'
    }
  },
  {
    name: 'Hungary',
    code: {
      label: 'Hungary',
      value: 'HU'
    }
  },
  {
    name: 'Iceland',
    code: {
      label: 'Iceland',
      value: 'IS'
    }
  },
  {
    name: 'Ireland',
    code: {
      label: 'Ireland',
      value: 'IE'
    }
  },
  {
    name: 'Italy',
    code: {
      label: 'Italy',
      value: 'IT'
    }
  },
  {
    name: 'Latvia',
    code: {
      label: 'Latvia',
      value: 'LV'
    }
  },
  {
    name: 'Liechtenstein',
    code: {
      label: 'Liechtenstein',
      value: 'LI'
    }
  },
  {
    name: 'Lithuania',
    code: {
      label: 'Lithuania',
      value: 'LT'
    }
  },
  {
    name: 'Luxembourg',
    code: {
      label: 'Luxembourg',
      value: 'LU'
    }
  },
  {
    name: 'Macedonia',
    code: {
      label: 'Macedonia',
      value: 'MK'
    }
  },
  {
    name: 'Malta',
    code: {
      label: 'Malta',
      value: 'MT'
    }
  },
  {
    name: 'Monaco',
    code: {
      label: 'Monaco',
      value: 'MC'
    }
  },
  {
    name: 'Montenegro',
    code: {
      label: 'Montenegro',
      value: 'ME'
    }
  },
  {
    name: 'Netherlands',
    code: {
      label: 'Netherlands',
      value: 'NL'
    }
  },
  {
    name: 'Norway',
    code: {
      label: 'Norway',
      value: 'NO'
    }
  },
  {
    name: 'Poland',
    code: {
      label: 'Poland',
      value: 'PL'
    }
  },
  {
    name: 'Portugal',
    code: {
      label: 'Portugal',
      value: 'PT'
    }
  },
  {
    name: 'Romania',
    code: {
      label: 'Romania',
      value: 'RO'
    }
  },
  {
    name: 'San Marino',
    code: {
      label: 'San Marino',
      value: 'SM'
    }
  },
  {
    name: 'Serbia',
    code: {
      label: 'Serbia',
      value: 'RS'
    }
  },
  {
    name: 'Slovakia',
    code: {
      label: 'Slovakia',
      value: 'SK'
    }
  },
  {
    name: 'Slovenia',
    code: {
      label: 'Slovenia',
      value: 'SI'
    }
  },
  {
    name: 'Spain',
    code: {
      label: 'Spain',
      value: 'ES'
    }
  },
  {
    name: 'Sweden',
    code: {
      label: 'Sweden',
      value: 'SE'
    }
  },
  {
    name: 'Switzerland',
    code: {
      label: 'Switzerland',
      value: 'CH'
    }
  },
  {
    name: 'Ukraine',
    code: {
      label: 'Ukraine',
      value: 'UA'
    }
  },
  {
    name: 'United Kingdom',
    code: {
      label: 'United Kingdom',
      value: 'GB'
    }
  }
];
