
  import { defineComponent, onMounted, Ref, ref } from 'vue';
  import firebase from 'firebase';
  import useToast from '@/composables/use-toast';
  import {
    FUN_ICE_BREAKER_QUESTION_TYPES,
    FunIceBreakerQuestionConfig
  } from '@/interfaces/funicebreaker';
  import { useI18n } from 'vue-i18n';
  import { v4 as uuidv4 } from 'uuid';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'SuperUserFunTeamBiddingQuestions',
    setup() {
      const questions: Ref = ref({ list: [] });
      const { errorToast, successToast } = useToast();
      const { t } = useI18n();

      onMounted(async () => {
        try {
          questions.value = await getAllFunIceBreakerQuestions();
        } catch {
          errorToast('Something went wrong. Please reload the page.');
        }
      });

      const typeOptions: Ref<{ value: string; label: string }[]> = ref([
        {
          value: FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE,
          label: t(`funIceBreaker.customQuestions.types.${FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE}`)
        },
        {
          value: FUN_ICE_BREAKER_QUESTION_TYPES.TRUTHLIE,
          label: t(`funIceBreaker.customQuestions.types.${FUN_ICE_BREAKER_QUESTION_TYPES.TRUTHLIE}`)
        }
      ]);

      const getAllFunIceBreakerQuestions = async () => {
        return await db
          .doc('funIceBreakerQuestions/default')
          .get()
          .then(doc => {
            return { id: doc.id, ...doc.data() };
          })
          .catch(e => {
            errorToast(e);
          });
      };

      const handleSaveQuestions = async () => {
        try {
          await db.doc('funIceBreakerQuestions/default').update({
            list: questions.value.list
          });
          successToast(t('success.saved'));
        } catch (e) {
          errorToast(e);
        }
      };

      const handleDeleteQuestionAndSave = async (id: string) => {
        questions.value.list = questions.value.list.filter(
          (q: FunIceBreakerQuestionConfig) => q.id !== id
        );

        await handleSaveQuestions();
      };

      const addNew = () => {
        // make id more reliable
        const id = uuidv4();
        const payload: FunIceBreakerQuestionConfig = {
          id,
          textPl: '',
          textEn: '',
          type: FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE
        };
        questions.value.list = [payload, ...questions.value.list];
      };

      return {
        questions,
        FUN_ICE_BREAKER_QUESTION_TYPES,
        handleSaveQuestions,
        addNew,
        handleDeleteQuestionAndSave,
        typeOptions
      };
    }
  });
