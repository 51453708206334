export const FUN_TEAM_CLUES_WORDS = {
  en: [
    'ability',
    'above',
    'absolute',
    'academy',
    'accent',
    'access',
    'accident',
    'account',
    'accused',
    'act',
    'acting',
    'action',
    'activity',
    'actor',
    'actress',
    'acts',
    'adam',
    'add',
    'address',
    'adult',
    'advance',
    'advantage',
    'adventure',
    'advice',
    'affair',
    'affairs',
    'africa',
    'afternoon',
    'age',
    'agency',
    'agent',
    'agreement',
    'aid',
    'aim',
    'air',
    'airport',
    'alarm',
    'albert',
    'album',
    'alcohol',
    'alert',
    'ali',
    'alien',
    'allah',
    'ambulance',
    'amen',
    'america',
    'american',
    'amount',
    'ana',
    'ancient',
    'andrew',
    'angel',
    'anger',
    'angle',
    'animal',
    'anna',
    'anne',
    'anniversary',
    'annoying',
    'answer',
    'anthony',
    'anti',
    'anybody',
    'anyone',
    'apartment',
    'appearance',
    'applause',
    'apple',
    'appointment',
    'approach',
    'approaching',
    'april',
    'are',
    'area',
    'argument',
    'arm',
    'arms',
    'army',
    'arrest',
    'arse',
    'art',
    'arthur',
    'article',
    'artist',
    'arts',
    'aside',
    'asking',
    'ass',
    'assault',
    'asshole',
    'assistant',
    'ate',
    'atmosphere',
    'attack',
    'attempt',
    'attention',
    'attitude',
    'attorney',
    'audience',
    'audition',
    'august',
    'aunt',
    'auntie',
    'authorities',
    'authority',
    'average',
    'award',
    'babe',
    'baby',
    'back',
    'background',
    'backup',
    'bad',
    'bag',
    'bail',
    'balance',
    'ball',
    'band',
    'bang',
    'bank',
    'banks',
    'bar',
    'bars',
    'base',
    'baseball',
    'basement',
    'basic',
    'basketball',
    'bastard',
    'bat',
    'bath',
    'bathroom',
    'battery',
    'battle',
    'bay',
    'beach',
    'bear',
    'beast',
    'beat',
    'beating',
    'beats',
    'beauty',
    'bed',
    'bedroom',
    'bee',
    'beef',
    'beer',
    'begging',
    'begin',
    'beginning',
    'behalf',
    'behavior',
    'behind',
    'being',
    'bell',
    'belly',
    'beloved',
    'belt',
    'ben',
    'bend',
    'benefit',
    'benny',
    'berlin',
    'best',
    'bet',
    'better',
    'bible',
    'bike',
    'bill',
    'billion',
    'billy',
    'bingo',
    'bird',
    'birth',
    'birthday',
    'bishop',
    'bit',
    'bitch',
    'bite',
    'bitter',
    'black',
    'blade',
    'blah',
    'blame',
    'blast',
    'bleeding',
    'blessing',
    'blind',
    'block',
    'blood',
    'blow',
    'blowing',
    'blue',
    'board',
    'boat',
    'bob',
    'bobby',
    'body',
    'bomb',
    'bond',
    'bone',
    'bones',
    'boo',
    'book',
    'boom',
    'booze',
    'border',
    'boring',
    'born',
    'boss',
    'boston',
    'bother',
    'bottle',
    'bottom',
    'bound',
    'bow',
    'bowl',
    'box',
    'boxing',
    'boy',
    'boyfriend',
    'brad',
    'brain',
    'brand',
    'brave',
    'bravo',
    'brazil',
    'bread',
    'break',
    'breakfast',
    'breaking',
    'breast',
    'breath',
    'breathing',
    'bride',
    'bridge',
    'bringing',
    'british',
    'brother',
    'brown',
    'bruce',
    'brush',
    'buck',
    'buddy',
    'budget',
    'bug',
    'build',
    'building',
    'bull',
    'bullet',
    'bullshit',
    'bum',
    'bunch',
    'burden',
    'burn',
    'burning',
    'burns',
    'bus',
    'bush',
    'business',
    'bust',
    'butt',
    'butter',
    'button',
    'buy',
    'buying',
    'bye',
    'cab',
    'cabin',
    'cable',
    'caesar',
    'cage',
    'cake',
    'california',
    'call',
    'calling',
    'calm',
    'camera',
    'camp',
    'campaign',
    'can',
    'canada',
    'cancel',
    'cancer',
    'candy',
    'cap',
    'capital',
    'captain',
    'car',
    'card',
    'cards',
    'care',
    'career',
    'carlos',
    'carry',
    'carter',
    'case',
    'cash',
    'casino',
    'cast',
    'castle',
    'cat',
    'catch',
    'catherine',
    'catholic',
    'cause',
    'cave',
    'cell',
    'center',
    'central',
    'centre',
    'century',
    'ceremony',
    'chain',
    'chair',
    'chairman',
    'challenge',
    'champ',
    'champagne',
    'champion',
    'chance',
    'change',
    'channel',
    'chaos',
    'chapter',
    'character',
    'charge',
    'charity',
    'charles',
    'charlotte',
    'charm',
    'chase',
    'chat',
    'chattering',
    'cheat',
    'cheating',
    'check',
    'cheer',
    'cheering',
    'cheese',
    'chef',
    'chest',
    'chi',
    'chicago',
    'chick',
    'chicken',
    'chief',
    'child',
    'childhood',
    'chill',
    'china',
    'chinese',
    'chip',
    'chips',
    'chocolate',
    'choice',
    'chop',
    'chosen',
    'christ',
    'christian',
    'christmas',
    'chuck',
    'church',
    'cigarette',
    'cinema',
    'circle',
    'circus',
    'citizen',
    'city',
    'claim',
    'clark',
    'class',
    'classic',
    'clean',
    'cleaning',
    'clear',
    'client',
    'cliff',
    'climb',
    'clinic',
    'clock',
    'close',
    'closer',
    'closet',
    'closing',
    'clothes',
    'cloud',
    'clown',
    'club',
    'clue',
    'coach',
    'coast',
    'coat',
    'cock',
    'code',
    'coffee',
    'coincidence',
    'coke',
    'cold',
    'colleague',
    'collect',
    'collection',
    'college',
    'colonel',
    'color',
    'colors',
    'colour',
    'combat',
    'come',
    'comfort',
    'coming',
    'command',
    'commander',
    'commercial',
    'commission',
    'committee',
    'common',
    'community',
    'company',
    'competition',
    'complex',
    'computer',
    'comrade',
    'concentrate',
    'concept',
    'concern',
    'concert',
    'condition',
    'conditions',
    'conference',
    'confession',
    'confidence',
    'connection',
    'conscience',
    'construction',
    'contact',
    'contest',
    'contract',
    'control',
    'conversation',
    'cook',
    'cooking',
    'cool',
    'cop',
    'copy',
    'core',
    'corn',
    'corner',
    'corpse',
    'cos',
    'cost',
    'costs',
    'costume',
    'couch',
    'council',
    'count',
    'counter',
    'counting',
    'country',
    'county',
    'couple',
    'courage',
    'course',
    'court',
    'cousin',
    'cover',
    'cow',
    'coward',
    'cows',
    'crack',
    'crane',
    'crap',
    'crash',
    'crazy',
    'cream',
    'creature',
    'credit',
    'crew',
    'crime',
    'criminal',
    'crisis',
    'cross',
    'crowd',
    'crown',
    'crush',
    'cry',
    'crying',
    'crystal',
    'culture',
    'cunt',
    'cup',
    'cure',
    'current',
    'curse',
    'custody',
    'customer',
    'cut',
    'cutting',
    'dad',
    'daddy',
    'daily',
    'damage',
    'damn',
    'damned',
    'dance',
    'dancer',
    'dancing',
    'danger',
    'daniel',
    'dare',
    'dark',
    'darkness',
    'darling',
    'data',
    'date',
    'dating',
    'daughter',
    'david',
    'dawn',
    'day',
    'days',
    'dead',
    'deaf',
    'deal',
    'dealer',
    'dealing',
    'dean',
    'dear',
    'death',
    'debt',
    'december',
    'decision',
    'deck',
    'deep',
    'defeat',
    'defense',
    'degree',
    'delicious',
    'delivery',
    'demand',
    'demon',
    'department',
    'desert',
    'design',
    'desire',
    'desk',
    'desperate',
    'despite',
    'destiny',
    'destruction',
    'detail',
    'details',
    'detective',
    'development',
    'device',
    'devil',
    'diamond',
    'diary',
    'dick',
    'die',
    'diet',
    'difference',
    'dig',
    'dinner',
    'direction',
    'director',
    'dirt',
    'disaster',
    'disease',
    'dish',
    'distance',
    'district',
    'dive',
    'divine',
    'division',
    'divorce',
    'dna',
    'doc',
    'doctor',
    'dog',
    'doll',
    'dollar',
    'dong',
    'donkey',
    'door',
    'double',
    'doubt',
    'down',
    'downtown',
    'dozen',
    'drag',
    'dragon',
    'drama',
    'draw',
    'drawing',
    'dream',
    'dreaming',
    'dress',
    'drew',
    'drink',
    'drinking',
    'drive',
    'driver',
    'driving',
    'drop',
    'drove',
    'drug',
    'drunk',
    'dry',
    'duck',
    'dude',
    'due',
    'duke',
    'dump',
    'dust',
    'duty',
    'dying',
    'dylan',
    'ear',
    'earth',
    'ease',
    'east',
    'eating',
    'eats',
    'economy',
    'edge',
    'education',
    'edward',
    'effect',
    'effects',
    'effort',
    'egg',
    'eggs',
    'eight',
    'elder',
    'election',
    'electric',
    'electricity',
    'elephant',
    'elevator',
    'eleven',
    'elizabeth',
    'emergency',
    'emperor',
    'empire',
    'empty',
    'end',
    'ending',
    'enemy',
    'energy',
    'engine',
    'england',
    'english',
    'enough',
    'entire',
    'entrance',
    'environment',
    'equal',
    'equipment',
    'escape',
    'escort',
    'estate',
    'europe',
    'european',
    'eve',
    'even',
    'evening',
    'event',
    'everybody',
    'everyone',
    'evidence',
    'evil',
    'exam',
    'example',
    'exchange',
    'excuse',
    'exercise',
    'existence',
    'exit',
    'experience',
    'experiment',
    'expert',
    'explanation',
    'explosion',
    'express',
    'expression',
    'extra',
    'extreme',
    'eye',
    'eyes',
    'face',
    'facing',
    'fact',
    'factory',
    'failure',
    'fair',
    'fairy',
    'faith',
    'fake',
    'fall',
    'falls',
    'familiar',
    'family',
    'fan',
    'fancy',
    'fantasy',
    'far',
    'farewell',
    'farm',
    'farmer',
    'fashion',
    'fast',
    'fat',
    'fate',
    'father',
    'fault',
    'favor',
    'favorite',
    'favour',
    'favourite',
    'fear',
    'fed',
    'federal',
    'feed',
    'feeding',
    'feel',
    'feeling',
    'feelings',
    'fell',
    'fella',
    'fellow',
    'felt',
    'female',
    'fence',
    'festival',
    'fetch',
    'fever',
    'few',
    'field',
    'fields',
    'fifteen',
    'fifth',
    'fifty',
    'fight',
    'fighter',
    'fighting',
    'figure',
    'file',
    'fill',
    'film',
    'final',
    'find',
    'finding',
    'fine',
    'finger',
    'finish',
    'fire',
    'firing',
    'firm',
    'first',
    'fish',
    'fishing',
    'fit',
    'five',
    'fix',
    'flag',
    'flash',
    'flat',
    'flesh',
    'flies',
    'flight',
    'floor',
    'florida',
    'flow',
    'flower',
    'fly',
    'flying',
    'focus',
    'folks',
    'following',
    'food',
    'fool',
    'foot',
    'football',
    'for',
    'force',
    'forest',
    'form',
    'former',
    'forth',
    'fortune',
    'forty',
    'forward',
    'found',
    'four',
    'fourth',
    'fox',
    'frame',
    'france',
    'frank',
    'freak',
    'free',
    'freedom',
    'freeze',
    'freezing',
    'french',
    'friday',
    'fridge',
    'friend',
    'friendly',
    'friendship',
    'front',
    'fruit',
    'fuck',
    'fucker',
    'fucking',
    'fuel',
    'full',
    'fun',
    'funeral',
    'funny',
    'furniture',
    'future',
    'gain',
    'gambling',
    'game',
    'gang',
    'garage',
    'garbage',
    'garden',
    'gary',
    'gas',
    'gate',
    'gates',
    'gather',
    'gay',
    'gear',
    'gee',
    'general',
    'generation',
    'genius',
    'gentleman',
    'george',
    'german',
    'germany',
    'get',
    'getting',
    'ghost',
    'giant',
    'gift',
    'girl',
    'girlfriend',
    'give',
    'given',
    'giving',
    'glad',
    'glass',
    'glasses',
    'glory',
    'goal',
    'goat',
    'god',
    'goddess',
    'going',
    'gold',
    'golf',
    'good',
    'goodbye',
    'goodness',
    'government',
    'governor',
    'grab',
    'grace',
    'grade',
    'grand',
    'grandfather',
    'grandma',
    'grandmother',
    'grandpa',
    'grandson',
    'granny',
    'grant',
    'grass',
    'grave',
    'gray',
    'great',
    'greek',
    'green',
    'gross',
    'ground',
    'group',
    'growing',
    'guarantee',
    'guard',
    'guess',
    'guest',
    'guide',
    'guilt',
    'guitar',
    'gun',
    'guts',
    'guy',
    'gym',
    'habit',
    'hail',
    'hair',
    'half',
    'hall',
    'hammer',
    'han',
    'hand',
    'handle',
    'hands',
    'hang',
    'hanging',
    'hank',
    'happening',
    'happiness',
    'harm',
    'hat',
    'hate',
    'have',
    'haven',
    'head',
    'headache',
    'heading',
    'headquarters',
    'health',
    'hearing',
    'heart',
    'hearts',
    'heat',
    'heaven',
    'heavens',
    'heavy',
    'helen',
    'helicopter',
    'hell',
    'hello',
    'help',
    'helping',
    'henry',
    'here',
    'hero',
    'hide',
    'hiding',
    'high',
    'highness',
    'highway',
    'hill',
    'hip',
    'hire',
    'history',
    'hit',
    'hitler',
    'hitting',
    'hold',
    'holding',
    'hole',
    'holiday',
    'hollywood',
    'holy',
    'home',
    'homework',
    'honey',
    'honeymoon',
    'honor',
    'honour',
    'hood',
    'hook',
    'hop',
    'hope',
    'horn',
    'horse',
    'hospital',
    'host',
    'hostage',
    'hotel',
    'hour',
    'hours',
    'house',
    'howard',
    'hug',
    'human',
    'humanity',
    'humans',
    'hundred',
    'hunt',
    'hunter',
    'hunting',
    'hurry',
    'hurt',
    'hurting',
    'husband',
    'ice',
    'idea',
    'identity',
    'idiot',
    'ill',
    'illness',
    'image',
    'imagination',
    'impact',
    'impossible',
    'impression',
    'inch',
    'incident',
    'increase',
    'india',
    'indian',
    'individual',
    'industry',
    'influence',
    'information',
    'innocent',
    'inside',
    'inspector',
    'instance',
    'insult',
    'insurance',
    'intelligence',
    'interest',
    'internet',
    'interrupt',
    'interview',
    'invitation',
    'invite',
    'iraq',
    'iron',
    'island',
    'israel',
    'issue',
    'italian',
    'italy',
    'jack',
    'jacket',
    'jackson',
    'jacob',
    'jail',
    'jam',
    'james',
    'jan',
    'japan',
    'japanese',
    'jason',
    'jay',
    'jean',
    'jenny',
    'jerk',
    'jerry',
    'jersey',
    'jesus',
    'jet',
    'jew',
    'jimmy',
    'job',
    'john',
    'johnny',
    'johnson',
    'join',
    'joint',
    'joke',
    'jones',
    'jordan',
    'joseph',
    'journalist',
    'journey',
    'joy',
    'judge',
    'juice',
    'july',
    'jump',
    'jumping',
    'june',
    'jung',
    'jungle',
    'junior',
    'junk',
    'jury',
    'justice',
    'karen',
    'keep',
    'keeping',
    'kelly',
    'ken',
    'key',
    'kick',
    'kicking',
    'kid',
    'kill',
    'killer',
    'killing',
    'kind',
    'king',
    'kingdom',
    'kiss',
    'kissing',
    'kitchen',
    'kitty',
    'knee',
    'knife',
    'knock',
    'knocking',
    'know',
    'knowing',
    'knowledge',
    'korea',
    'korean',
    'lab',
    'lack',
    'lad',
    'lady',
    'lake',
    'land',
    'landing',
    'language',
    'large',
    'last',
    'latest',
    'latin',
    'laugh',
    'laughter',
    'launch',
    'laundry',
    'law',
    'laws',
    'lawyer',
    'lay',
    'lead',
    'leader',
    'leaders',
    'leading',
    'league',
    'learning',
    'least',
    'leave',
    'leaving',
    'led',
    'lee',
    'left',
    'leg',
    'legend',
    'legs',
    'leo',
    'lesson',
    'let',
    'letter',
    'letters',
    'letting',
    'level',
    'liar',
    'library',
    'license',
    'lie',
    'lied',
    'lieutenant',
    'life',
    'lifetime',
    'lift',
    'light',
    'lighter',
    'lightning',
    'like',
    'lily',
    'limit',
    'line',
    'ling',
    'link',
    'lion',
    'liquor',
    'list',
    'listening',
    'little',
    'liver',
    'living',
    'load',
    'loan',
    'local',
    'location',
    'lock',
    'london',
    'longer',
    'look',
    'looking',
    'lord',
    'loser',
    'loss',
    'lost',
    'lot',
    'lots',
    'louis',
    'love',
    'lovely',
    'lover',
    'low',
    'lower',
    'luck',
    'lucy',
    'luke',
    'lunch',
    'lying',
    'mac',
    'machine',
    'madam',
    'madame',
    'madness',
    'magazine',
    'magic',
    'maid',
    'mail',
    'main',
    'majesty',
    'major',
    'make',
    'making',
    'male',
    'mall',
    'mama',
    'man',
    'manager',
    'map',
    'march',
    'maria',
    'mark',
    'market',
    'marks',
    'marriage',
    'married',
    'mars',
    'martial',
    'martin',
    'mary',
    'mask',
    'mass',
    'massage',
    'master',
    'match',
    'mate',
    'material',
    'math',
    'matt',
    'matter',
    'max',
    'may',
    'maya',
    'mayor',
    'meal',
    'mean',
    'meaning',
    'means',
    'meat',
    'medical',
    'medication',
    'medicine',
    'meet',
    'meeting',
    'member',
    'memory',
    'men',
    'mention',
    'mercy',
    'mess',
    'message',
    'metal',
    'method',
    'mexico',
    'michael',
    'mickey',
    'middle',
    'midnight',
    'might',
    'mike',
    'mile',
    'military',
    'milk',
    'million',
    'min',
    'mind',
    'mine',
    'minister',
    'minor',
    'minute',
    'minutes',
    'miracle',
    'mirror',
    'miss',
    'mission',
    'mistake',
    'mister',
    'mistress',
    'mix',
    'mobile',
    'model',
    'modern',
    'molly',
    'mom',
    'moment',
    'mommy',
    'mon',
    'monday',
    'money',
    'monk',
    'monkey',
    'monsieur',
    'monster',
    'month',
    'mood',
    'moon',
    'moral',
    'more',
    'morgan',
    'morning',
    'moron',
    'moscow',
    'mother',
    'motherfucker',
    'motion',
    'mountain',
    'mouse',
    'mouth',
    'move',
    'movement',
    'movie',
    'mrs',
    'much',
    'mud',
    'mum',
    'mumbai',
    'mummy',
    'murder',
    'murderer',
    'museum',
    'music',
    'must',
    'mystery',
    'nail',
    'name',
    'names',
    'narrator',
    'nation',
    'national',
    'natural',
    'nature',
    'necessary',
    'neck',
    'need',
    'negative',
    'neighbor',
    'neighborhood',
    'nephew',
    'nerve',
    'nerves',
    'net',
    'network',
    'news',
    'newspaper',
    'nice',
    'nick',
    'night',
    'nightmare',
    'nina',
    'nine',
    'noble',
    'nobody',
    'noise',
    'none',
    'nonsense',
    'noon',
    'normal',
    'north',
    'nose',
    'note',
    'nothing',
    'notice',
    'novel',
    'now',
    'nowadays',
    'nowhere',
    'number',
    'numbers',
    'nurse',
    'nut',
    'object',
    'occasion',
    'ocean',
    'october',
    'offer',
    'offering',
    'office',
    'officer',
    'official',
    'oil',
    'okay',
    'old',
    'one',
    'open',
    'opening',
    'opera',
    'operation',
    'opinion',
    'opportunity',
    'opposite',
    'option',
    'orange',
    'order',
    'ordinary',
    'organization',
    'original',
    'oscar',
    'out',
    'outfit',
    'outside',
    'over',
    'owner',
    'oxygen',
    'pack',
    'package',
    'page',
    'pain',
    'paint',
    'painting',
    'pair',
    'pal',
    'palace',
    'pan',
    'panic',
    'panting',
    'pants',
    'papa',
    'paper',
    'papers',
    'paradise',
    'pardon',
    'paris',
    'park',
    'parking',
    'part',
    'particular',
    'partner',
    'parts',
    'party',
    'pass',
    'passing',
    'passion',
    'passport',
    'past',
    'path',
    'patience',
    'patient',
    'patrick',
    'paul',
    'pay',
    'payment',
    'peace',
    'pee',
    'pen',
    'penis',
    'penny',
    'people',
    'percent',
    'perfect',
    'performance',
    'period',
    'permission',
    'person',
    'personal',
    'personality',
    'pervert',
    'pet',
    'peter',
    'phone',
    'photo',
    'photograph',
    'photographer',
    'piano',
    'pick',
    'picking',
    'picture',
    'pie',
    'piece',
    'pierre',
    'pig',
    'pill',
    'pilot',
    'pink',
    'pipe',
    'piss',
    'pitch',
    'pity',
    'pizza',
    'place',
    'plain',
    'plan',
    'plane',
    'planet',
    'planning',
    'plant',
    'plastic',
    'plate',
    'play',
    'player',
    'playing',
    'pleasure',
    'plenty',
    'plus',
    'pocket',
    'poem',
    'poet',
    'poetry',
    'point',
    'poison',
    'pole',
    'police',
    'policeman',
    'policy',
    'polish',
    'politics',
    'pool',
    'poor',
    'pop',
    'pope',
    'population',
    'pork',
    'porn',
    'port',
    'position',
    'positive',
    'possibility',
    'possible',
    'post',
    'pot',
    'potato',
    'potential',
    'pound',
    'powder',
    'power',
    'practice',
    'praise',
    'prayer',
    'presence',
    'present',
    'president',
    'press',
    'pressure',
    'pretend',
    'pretending',
    'price',
    'prick',
    'pride',
    'priest',
    'prime',
    'prince',
    'princess',
    'principal',
    'print',
    'prison',
    'prisoner',
    'private',
    'prize',
    'pro',
    'problem',
    'process',
    'produce',
    'producer',
    'product',
    'production',
    'professional',
    'professor',
    'profit',
    'program',
    'progress',
    'project',
    'promise',
    'proof',
    'property',
    'proposal',
    'protection',
    'psycho',
    'public',
    'pull',
    'pulling',
    'pulse',
    'punch',
    'punishment',
    'punk',
    'purpose',
    'purse',
    'push',
    'pushing',
    'pussy',
    'put',
    'putting',
    'quality',
    'quarter',
    'queen',
    'question',
    'quick',
    'quiet',
    'rabbit',
    'race',
    'rachel',
    'radio',
    'rain',
    'raise',
    'raj',
    'ram',
    'range',
    'rape',
    'rat',
    'rate',
    'ray',
    'reach',
    'reaction',
    'read',
    'reading',
    'ready',
    'real',
    'reality',
    'reason',
    'recall',
    'recent',
    'record',
    'recording',
    'red',
    'refuse',
    'register',
    'regret',
    'regular',
    'relationship',
    'release',
    'relief',
    'religion',
    'religious',
    'remains',
    'remote',
    'remove',
    'rent',
    'repeat',
    'report',
    'reporter',
    'reputation',
    'request',
    'rescue',
    'research',
    'respect',
    'response',
    'rest',
    'restaurant',
    'result',
    'return',
    'revenge',
    'revolution',
    'reward',
    'rice',
    'rich',
    'rick',
    'ride',
    'riding',
    'right',
    'ring',
    'ringing',
    'rings',
    'rip',
    'rise',
    'risk',
    'river',
    'road',
    'roads',
    'robbery',
    'robert',
    'robot',
    'rock',
    'rocket',
    'role',
    'roll',
    'rolling',
    'roman',
    'romance',
    'romantic',
    'rome',
    'ron',
    'roof',
    'room',
    'rooms',
    'rope',
    'rose',
    'rough',
    'round',
    'route',
    'routine',
    'row',
    'royal',
    'rub',
    'rubbish',
    'ruin',
    'rule',
    'run',
    'running',
    'rush',
    'russia',
    'russian',
    'sack',
    'sacrifice',
    'safe',
    'safety',
    'saint',
    'sake',
    'salad',
    'salary',
    'sale',
    'sales',
    'sally',
    'salt',
    'sam',
    'same',
    'sample',
    'sand',
    'sandwich',
    'sang',
    'santa',
    'sarah',
    'sat',
    'satellite',
    'saturday',
    'sauce',
    'save',
    'saving',
    'saw',
    'say',
    'saying',
    'scale',
    'scare',
    'scene',
    'schedule',
    'school',
    'science',
    'scientist',
    'score',
    'scott',
    'scratch',
    'scream',
    'screaming',
    'screen',
    'screw',
    'script',
    'sea',
    'seal',
    'search',
    'season',
    'seat',
    'seats',
    'sec',
    'second',
    'secret',
    'secretary',
    'section',
    'security',
    'see',
    'seeing',
    'seek',
    'self',
    'sell',
    'selling',
    'senator',
    'sending',
    'senior',
    'sense',
    'sensitive',
    'sent',
    'sentence',
    'separate',
    'september',
    'sergeant',
    'series',
    'servant',
    'serve',
    'service',
    'services',
    'set',
    'setting',
    'settle',
    'seven',
    'sex',
    'shadow',
    'shake',
    'shaking',
    'shame',
    'shape',
    'share',
    'shark',
    'sharp',
    'shed',
    'sheep',
    'shell',
    'shelter',
    'sheriff',
    'shift',
    'shine',
    'ship',
    'shirt',
    'shit',
    'shock',
    'shoe',
    'shoes',
    'shoot',
    'shooting',
    'shop',
    'shopping',
    'shore',
    'short',
    'shot',
    'shoulder',
    'shout',
    'shouting',
    'show',
    'shower',
    'showing',
    'shy',
    'sick',
    'side',
    'sight',
    'sign',
    'signal',
    'signature',
    'silence',
    'silly',
    'silver',
    'simon',
    'simple',
    'sin',
    'singer',
    'singing',
    'single',
    'sink',
    'sir',
    'sis',
    'sister',
    'site',
    'sitting',
    'situation',
    'six',
    'size',
    'skin',
    'skinny',
    'skip',
    'skull',
    'sky',
    'slap',
    'slave',
    'sleep',
    'sleeping',
    'slide',
    'slip',
    'slut',
    'small',
    'smart',
    'smell',
    'smile',
    'smiling',
    'smith',
    'smoke',
    'smoking',
    'smooth',
    'snake',
    'snap',
    'snow',
    'soap',
    'sobbing',
    'soccer',
    'social',
    'society',
    'soldier',
    'solid',
    'solution',
    'somebody',
    'someone',
    'something',
    'somewhere',
    'son',
    'song',
    'sooner',
    'sort',
    'soul',
    'sound',
    'soup',
    'source',
    'south',
    'space',
    'spain',
    'spanish',
    'spare',
    'speaking',
    'special',
    'species',
    'specific',
    'speech',
    'speed',
    'spell',
    'spending',
    'spider',
    'spin',
    'spirit',
    'spirits',
    'spit',
    'split',
    'spoke',
    'sport',
    'spot',
    'spread',
    'spring',
    'spy',
    'squad',
    'square',
    'squeeze',
    'staff',
    'stage',
    'stairs',
    'stake',
    'stand',
    'standard',
    'standing',
    'star',
    'stare',
    'start',
    'starting',
    'starving',
    'state',
    'statement',
    'station',
    'status',
    'stay',
    'stays',
    'steady',
    'steal',
    'stealing',
    'steel',
    'step',
    'stephen',
    'steps',
    'stick',
    'still',
    'stink',
    'stock',
    'stole',
    'stomach',
    'stone',
    'stop',
    'stopping',
    'stops',
    'store',
    'storm',
    'story',
    'straight',
    'stranger',
    'street',
    'strength',
    'stress',
    'stretch',
    'strike',
    'strip',
    'struggle',
    'student',
    'studio',
    'study',
    'studying',
    'stuff',
    'stupid',
    'style',
    'subject',
    'success',
    'suck',
    'sue',
    'suffering',
    'sugar',
    'suicide',
    'suit',
    'suitcase',
    'summer',
    'sun',
    'sunday',
    'sung',
    'sunshine',
    'super',
    'supper',
    'supply',
    'support',
    'surface',
    'surgery',
    'surprise',
    'surrender',
    'suspect',
    'swallow',
    'sweat',
    'sweet',
    'sweetheart',
    'sweetie',
    'swim',
    'swimming',
    'swing',
    'switch',
    'sword',
    'symbol',
    'system',
    'table',
    'tail',
    'take',
    'taking',
    'tale',
    'talent',
    'talk',
    'talking',
    'talks',
    'tall',
    'tank',
    'tap',
    'tape',
    'target',
    'task',
    'taste',
    'tattoo',
    'tax',
    'taxi',
    'taylor',
    'tea',
    'teach',
    'teacher',
    'teaching',
    'team',
    'tear',
    'tears',
    'technology',
    'ted',
    'teddy',
    'teeth',
    'telephone',
    'television',
    'tell',
    'telling',
    'temperature',
    'temple',
    'ten',
    'term',
    'terms',
    'territory',
    'terrorist',
    'terry',
    'test',
    'testing',
    'texas',
    'text',
    'thanks',
    'theater',
    'theatre',
    'theme',
    'then',
    'theory',
    'therapy',
    'there',
    'thick',
    'thief',
    'thing',
    'things',
    'think',
    'thinking',
    'third',
    'thirty',
    'thomas',
    'thou',
    'thought',
    'thousand',
    'threat',
    'three',
    'throat',
    'throw',
    'thunder',
    'thursday',
    'thus',
    'ticket',
    'tie',
    'tiger',
    'till',
    'time',
    'times',
    'timing',
    'tip',
    'title',
    'toast',
    'today',
    'toilet',
    'tokyo',
    'tom',
    'tomorrow',
    'tone',
    'tongue',
    'tonight',
    'tons',
    'tooth',
    'top',
    'torture',
    'total',
    'touch',
    'touching',
    'tough',
    'tour',
    'towel',
    'tower',
    'town',
    'toy',
    'trace',
    'track',
    'trade',
    'tradition',
    'traffic',
    'tragedy',
    'train',
    'training',
    'traitor',
    'transfer',
    'trap',
    'trash',
    'travel',
    'traveling',
    'treasure',
    'treat',
    'treatment',
    'tree',
    'trial',
    'trick',
    'trigger',
    'trip',
    'troops',
    'trouble',
    'truck',
    'true',
    'trunk',
    'trust',
    'truth',
    'try',
    'tuesday',
    'tune',
    'tunnel',
    'turkey',
    'turn',
    'turning',
    'twelve',
    'twenty',
    'twins',
    'two',
    'type',
    'uncle',
    'underground',
    'underwear',
    'uniform',
    'union',
    'unit',
    'universe',
    'university',
    'unknown',
    'upper',
    'upset',
    'upstairs',
    'use',
    'using',
    'vacation',
    'valley',
    'valuable',
    'value',
    'vampire',
    'van',
    'vehicle',
    'version',
    'vice',
    'victim',
    'victor',
    'victory',
    'video',
    'view',
    'village',
    'violence',
    'virgin',
    'virus',
    'vision',
    'visit',
    'visiting',
    'vodka',
    'voice',
    'vote',
    'wait',
    'waiting',
    'wake',
    'walk',
    'walking',
    'wall',
    'wallet',
    'walter',
    'want',
    'war',
    'warning',
    'warrant',
    'warrior',
    'wash',
    'washing',
    'washington',
    'waste',
    'wasting',
    'watch',
    'watching',
    'water',
    'waters',
    'wave',
    'way',
    'wayne',
    'ways',
    'wealth',
    'weapon',
    'wear',
    'wearing',
    'weather',
    'wedding',
    'wednesday',
    'weed',
    'week',
    'weekend',
    'weight',
    'weil',
    'weird',
    'welcome',
    'well',
    'west',
    'western',
    'wet',
    'what',
    'wheel',
    'while',
    'whispering',
    'whistle',
    'white',
    'who',
    'whole',
    'whore',
    'why',
    'wife',
    'wild',
    'will',
    'willing',
    'wilson',
    'win',
    'wind',
    'window',
    'windows',
    'wine',
    'wing',
    'wings',
    'winner',
    'winning',
    'winter',
    'wipe',
    'wire',
    'wise',
    'wish',
    'witch',
    'witness',
    'wolf',
    'woman',
    'won',
    'wonder',
    'wood',
    'woods',
    'word',
    'words',
    'work',
    'worker',
    'working',
    'works',
    'world',
    'worry',
    'worrying',
    'worse',
    'worst',
    'worth',
    'wound',
    'wounded',
    'wow',
    'wrap',
    'writer',
    'writing',
    'wrong',
    'www',
    'yard',
    'year',
    'years',
    'yell',
    'yelling',
    'yellow',
    'yen',
    'yes',
    'yesterday',
    'york',
    'young',
    'youth',
    'zero',
    'zone',
    'abandoned',
    'able',
    'about',
    'abroad',
    'absurd',
    'accepted',
    'according',
    'ace',
    'active',
    'actual',
    'adopted',
    'advanced',
    'afraid',
    'after',
    'aggressive',
    'ago',
    'agreed',
    'ahead',
    'alike',
    'alive',
    'all',
    'alone',
    'alpha',
    'alright',
    'amazing',
    'angry',
    'another',
    'answering',
    'anxious',
    'any',
    'apart',
    'appropriate',
    'armed',
    'arranged',
    'ashamed',
    'asleep',
    'attached',
    'attractive',
    'available',
    'awake',
    'aware',
    'away',
    'awesome',
    'awful',
    'awkward',
    'bald',
    'based',
    'bass',
    'beaten',
    'beautiful',
    'becoming',
    'big',
    'bigger',
    'blank',
    'blanket',
    'blessed',
    'blocked',
    'blonde',
    'bloody',
    'blown',
    'booked',
    'bored',
    'both',
    'bothered',
    'brief',
    'bright',
    'brilliant',
    'broad',
    'broke',
    'broken',
    'built',
    'buried',
    'burned',
    'burnt',
    'busted',
    'busy',
    'capable',
    'careful',
    'catching',
    'certain',
    'changed',
    'changing',
    'charged',
    'charming',
    'cheap',
    'checked',
    'chemical',
    'cherry',
    'civil',
    'cleared',
    'clever',
    'closed',
    'comfortable',
    'committed',
    'communist',
    'complaining',
    'complete',
    'completed',
    'complicated',
    'concerned',
    'confident',
    'confirmed',
    'confused',
    'connected',
    'considered',
    'content',
    'continued',
    'contrary',
    'controlled',
    'convinced',
    'cooked',
    'correct',
    'covered',
    'creative',
    'creepy',
    'critical',
    'crossed',
    'cruel',
    'curious',
    'cursed',
    'cute',
    'damaged',
    'dangerous',
    'deadly',
    'decent',
    'decided',
    'defeated',
    'delicate',
    'depressed',
    'deserved',
    'designed',
    'destroyed',
    'determined',
    'developed',
    'different',
    'difficult',
    'direct',
    'directed',
    'dirty',
    'disappointed',
    'discovered',
    'disgusting',
    'distant',
    'disturbing',
    'divorced',
    'done',
    'downstairs',
    'dramatic',
    'drawn',
    'dreamed',
    'dressed',
    'driven',
    'dropping',
    'dumb',
    'each',
    'earlier',
    'early',
    'earned',
    'eastern',
    'easy',
    'economic',
    'educated',
    'effective',
    'embarrassed',
    'embarrassing',
    'emotional',
    'ended',
    'engaged',
    'enormous',
    'escaped',
    'eternal',
    'every',
    'everyday',
    'exact',
    'excellent',
    'excited',
    'exciting',
    'exhausted',
    'expected',
    'expensive',
    'experienced',
    'exposed',
    'fabulous',
    'faced',
    'fallen',
    'falling',
    'false',
    'famous',
    'fantastic',
    'fascinating',
    'figured',
    'filled',
    'filthy',
    'financial',
    'finished',
    'fired',
    'fixed',
    'flip',
    'floating',
    'focused',
    'fond',
    'fooling',
    'foolish',
    'forbidden',
    'forced',
    'foreign',
    'forgotten',
    'formed',
    'foul',
    'fresh',
    'fried',
    'frightened',
    'frozen',
    'further',
    'gathered',
    'generous',
    'gentle',
    'global',
    'goddamn',
    'golden',
    'gone',
    'gorgeous',
    'graduate',
    'granted',
    'grateful',
    'greatest',
    'grey',
    'grown',
    'guilty',
    'handed',
    'handsome',
    'happy',
    'hard',
    'harsh',
    'hated',
    'headed',
    'healthy',
    'heard',
    'hidden',
    'higher',
    'hired',
    'homeless',
    'honest',
    'hooked',
    'hopeless',
    'horny',
    'horrible',
    'hot',
    'huge',
    'hungry',
    'ideal',
    'illegal',
    'important',
    'impressed',
    'impressive',
    'incredible',
    'independent',
    'indistinct',
    'infected',
    'informed',
    'injured',
    'inner',
    'insane',
    'inspired',
    'instant',
    'intelligent',
    'intense',
    'interested',
    'interesting',
    'internal',
    'invisible',
    'involved',
    'jealous',
    'jewish',
    'joined',
    'joking',
    'just',
    'kept',
    'known',
    'laid',
    'lame',
    'larger',
    'late',
    'later',
    'laughing',
    'lazy',
    'learned',
    'legal',
    'lesbian',
    'less',
    'liked',
    'likely',
    'limited',
    'lit',
    'live',
    'loaded',
    'lonely',
    'long',
    'loose',
    'loud',
    'lousy',
    'loved',
    'loving',
    'loyal',
    'lucky',
    'mad',
    'made',
    'magical',
    'magnificent',
    'many',
    'marine',
    'massive',
    'mental',
    'merry',
    'mid',
    'mighty',
    'mini',
    'miserable',
    'missed',
    'missing',
    'mistaken',
    'mixed',
    'most',
    'motor',
    'moved',
    'moving',
    'murdered',
    'musical',
    'muslim',
    'mysterious',
    'naive',
    'naked',
    'nasty',
    'naughty',
    'nazi',
    'near',
    'nearby',
    'needed',
    'neither',
    'nervous',
    'new',
    'next',
    'northern',
    'noticed',
    'nuclear',
    'nuts',
    'obsessed',
    'obvious',
    'odd',
    'off',
    'older',
    'online',
    'only',
    'opened',
    'ordered',
    'organized',
    'other',
    'otherwise',
    'own',
    'owned',
    'packed',
    'paid',
    'painful',
    'painted',
    'pat',
    'pathetic',
    'paying',
    'peaceful',
    'physical',
    'pissed',
    'placed',
    'planned',
    'played',
    'pleasant',
    'pleased',
    'polite',
    'political',
    'popular',
    'powerful',
    'precious',
    'pregnant',
    'prepared',
    'pretty',
    'previous',
    'proper',
    'protected',
    'protecting',
    'proud',
    'published',
    'punished',
    'pure',
    'raining',
    'raised',
    'raising',
    'random',
    'randy',
    'raped',
    'rare',
    'raw',
    'realized',
    'rear',
    'reasonable',
    'received',
    'recognized',
    'recorded',
    'recovered',
    'related',
    'relative',
    'removed',
    'reported',
    'required',
    'responsible',
    'retired',
    'returning',
    'reverse',
    'ridiculous',
    'ripped',
    'rising',
    'rotten',
    'rubber',
    'ruby',
    'rude',
    'ruined',
    'sacred',
    'sad',
    'said',
    'sandy',
    'satisfied',
    'saved',
    'scared',
    'scary',
    'scientific',
    'searching',
    'secure',
    'selfish',
    'separated',
    'serial',
    'serious',
    'settled',
    'several',
    'sexual',
    'sexy',
    'shared',
    'sharing',
    'shining',
    'shitty',
    'shocked',
    'shut',
    'signed',
    'silent',
    'similar',
    'sixth',
    'slow',
    'smaller',
    'sneak',
    'soft',
    'sold',
    'solo',
    'solved',
    'some',
    'sometime',
    'sorry',
    'southern',
    'spent',
    'spiritual',
    'spoken',
    'stable',
    'staring',
    'sticking',
    'stopped',
    'strange',
    'strong',
    'struck',
    'stubborn',
    'stuck',
    'studied',
    'successful',
    'such',
    'sudden',
    'sunny',
    'superior',
    'supposed',
    'supreme',
    'sure',
    'surprised',
    'surrounded',
    'suspicious',
    'taken',
    'talented',
    'tasty',
    'temporary',
    'tense',
    'terrible',
    'terrific',
    'tested',
    'thai',
    'thin',
    'thirsty',
    'threatened',
    'threatening',
    'through',
    'thrown',
    'tied',
    'tight',
    'tiny',
    'tired',
    'together',
    'torn',
    'touched',
    'toward',
    'traditional',
    'tragic',
    'trained',
    'trapped',
    'treated',
    'tried',
    'trusted',
    'trying',
    'turned',
    'twin',
    'twisted',
    'typical',
    'ugly',
    'ultimate',
    'unable',
    'unbelievable',
    'unconscious',
    'under',
    'understood',
    'unfair',
    'unhappy',
    'unique',
    'united',
    'unlike',
    'unusual',
    'urgent',
    'used',
    'useful',
    'useless',
    'usual',
    'vain',
    'vanished',
    'various',
    'vast',
    'very',
    'violent',
    'wanted',
    'wanting',
    'warm',
    'warming',
    'washed',
    'wasted',
    'weak',
    'wee',
    'whatever',
    'wicked',
    'wide',
    'wizard',
    'wonderful',
    'wondering',
    'worn',
    'worried',
    'worthless',
    'worthy',
    'wrapped',
    'written',
    'younger'
  ],
  pl: [
    'umiejętność',
    'nad',
    'absolutny',
    'akademia',
    'akcent',
    'dostęp',
    'wypadek',
    'rachunek',
    'oskarżony',
    'działać',
    'gra aktorska',
    'akcja',
    'czynność',
    'aktor',
    'aktorka',
    'dzieje',
    'Adam',
    'Dodaj',
    'adres',
    'dorosły',
    'osiągnięcie',
    'korzyść',
    'przygoda',
    'Rada',
    'afera',
    'sprawy',
    'Afryka',
    'popołudnie',
    'wiek',
    'agencja',
    'agent',
    'porozumienie',
    'pomoc',
    'cel',
    'powietrze',
    'Lotnisko',
    'alarm',
    'Albert',
    'album',
    'alkohol',
    'alarm',
    'Ali',
    'obcy',
    'Allah',
    'ambulans',
    'Amen',
    'Ameryka',
    'amerykański',
    'ilość',
    'anana',
    'starożytny',
    'Andrzej',
    'anioł',
    'gniew',
    'kąt',
    'zwierzę',
    'Ania',
    'Anna',
    'rocznica',
    'irytujący',
    'odpowiedź',
    'Antoniusz',
    'anty',
    'ktoś',
    'ktokolwiek',
    'apartament',
    'wygląd',
    'oklaski',
    'jabłko',
    'wizyta, umówione spotkanie',
    'zbliżać się',
    'zbliżający się',
    'kwiecień',
    'są',
    'powierzchnia',
    'argument',
    'ramię',
    'ramiona',
    'armia',
    'aresztować',
    'tyłek',
    'Sztuka',
    'Artur',
    'artykuł',
    'artysta',
    'sztuka',
    'na boku',
    'pytać',
    'tyłek',
    'napaść',
    'dupek',
    'asystent',
    'jedli',
    'atmosfera',
    'atak',
    'próba',
    'Uwaga',
    'postawa',
    'adwokat',
    'publiczność',
    'przesłuchanie',
    'sierpień',
    'ciotka',
    'ciocia',
    'władze',
    'autorytet',
    'przeciętny',
    'nagroda',
    'dziecko',
    'dziecko',
    'plecy',
    'tło',
    'utworzyć kopię zapasową',
    'zły',
    'torba',
    'kaucja',
    'balansować',
    'piłka',
    'zespół muzyczny',
    'huk',
    'Bank',
    'banki',
    'bar',
    'słupy',
    'baza',
    'baseball',
    'piwnica',
    'podstawowy',
    'Koszykówka',
    'drań',
    'nietoperz',
    'kąpiel',
    'łazienka',
    'bateria',
    'bitwa',
    'Zatoka',
    'plaża',
    'Niedźwiedź',
    'bestia',
    'pokonać',
    'bicie',
    'bije',
    'piękno',
    'łóżko',
    'sypialnia',
    'pszczoła',
    'mięso wołowe',
    'piwo',
    'żebranie',
    'zaczynać',
    'początek',
    'w imieniu',
    'zachowanie',
    'za',
    'istnienie',
    'dzwonek',
    'brzuszek',
    'ukochany',
    'pasek',
    'ben',
    'schylać się',
    'korzyść',
    'benny',
    'Berlin',
    'Najlepsza',
    'Zakład',
    'lepszy',
    'Biblia',
    'rower',
    'rachunek',
    'miliard',
    'menażka',
    'bingo',
    'ptak',
    'narodziny',
    'urodziny',
    'biskup',
    'fragment',
    'suka',
    'ugryzienie',
    'gorzki',
    'czarny',
    'ostrze',
    'bla',
    'winić',
    'podmuch',
    'krwawienie',
    'błogosławieństwo',
    'ślepy',
    'blok',
    'krew',
    'cios',
    'dmuchanie',
    'niebieski',
    'tablica',
    'Łódź',
    'pion',
    'konstabl',
    'ciało',
    'bomba',
    'więź',
    'kość',
    'kości',
    'gwizd',
    'książka',
    'Bum',
    'gorzałka',
    'granica',
    'nudy',
    'urodzić się',
    'szef',
    'Boston',
    'niepokoić',
    'butelka',
    'Dolny',
    'oprawiony',
    'łuk',
    'miska',
    'skrzynka',
    'boks',
    'chłopiec',
    'chłopak',
    'ćwiek',
    'mózg',
    'Marka',
    'odważny',
    'Brawo',
    'Brazylia',
    'chleb',
    'zepsuć',
    'śniadanie',
    'łamanie',
    'pierś',
    'oddech',
    'oddechowy',
    'panna młoda',
    'most',
    'przynoszący',
    'brytyjski',
    'brat',
    'brązowy',
    'bruce',
    'Szczotka',
    'bryknięcie',
    'kumpel',
    'budżet',
    'błąd',
    'zbudować',
    'budynek',
    'byk',
    'pocisk',
    'głupie gadanie',
    'tyłek',
    'garść',
    'ciężar',
    'oparzenie',
    'palenie',
    'oparzenia',
    'autobus',
    'krzak',
    'biznes',
    'biust',
    'krupon',
    'masło',
    'przycisk',
    'kupić',
    'kupowanie',
    'PA',
    'taksówka',
    'kabina',
    'kabel',
    'Cezar',
    'klatka szybowa',
    'ciasto',
    'Kalifornia',
    'dzwonić',
    'powołanie',
    'spokojna',
    'kamera',
    'obóz',
    'kampania',
    'mogą',
    'Kanada',
    'Anuluj',
    'nowotwór',
    'współpracownik',
    'zebrać',
    'kolekcja',
    'Szkoła Wyższa',
    'pułkownik',
    'kolor',
    'zabarwienie',
    'kolor',
    'walka',
    'chodź',
    'komfort',
    'nadchodzący',
    'Komenda',
    'dowódca',
    'Reklama w telewizji',
    'zamawiać',
    'komisja',
    'pospolity',
    'społeczność',
    'Spółka',
    'konkurencja',
    'złożony',
    'komputer',
    'towarzysz',
    'koncentrować się',
    'pojęcie',
    'obawa',
    'koncert',
    'stan: schorzenie',
    'warunki',
    'konferencja',
    'wyznanie',
    'zaufanie',
    'połączenie',
    'sumienie',
    'budowa',
    'kontakt',
    'Konkurs',
    'kontrakt',
    'kontrola',
    'rozmowa',
    'ugotować',
    'gotowanie',
    'fajny',
    'policjant',
    'Kopiuj',
    'rdzeń',
    'kukurydza',
    'kąt',
    'zwłoki',
    'sałata',
    'koszt',
    'koszty',
    'kostium',
    'kanapa',
    'Rada',
    'liczyć',
    'licznik',
    'rachunkowość',
    'kraj',
    'Hrabstwo',
    'para',
    'odwaga',
    'kurs',
    'Sąd',
    'kuzyn',
    'pokrywa',
    'krowa',
    'tchórz',
    'krowy',
    'pękać',
    'dźwig',
    'bzdury',
    'rozbić się',
    'zwariowany',
    'krem',
    'kreatura',
    'kredyt',
    'załoga',
    'przestępczość',
    'kryminalista',
    'kryzys',
    'krzyż',
    'tłum',
    'Korona',
    'zmiażdżyć',
    'płakać',
    'płacz',
    'kryształ',
    'kultura',
    'cipa',
    'filiżanka',
    'lekarstwo',
    'obecny',
    'przekleństwo',
    'opieka',
    'klient',
    'ciąć',
    'ciąć',
    'tata',
    'tatuś',
    'codziennie',
    'szkoda',
    'cholerny',
    'cholernie',
    'taniec',
    'tancerz',
    'taniec',
    'zagrożenie',
    'Daniela',
    'odważyć się',
    'ciemny',
    'ciemność',
    'Kochanie',
    'dane',
    'Data',
    'randki',
    'córka',
    'Dawid',
    'Świt',
    'dzień',
    'dni',
    'martwy',
    'głuchy',
    'rozdać',
    'kupiec',
    'postępowanie',
    'dziekan',
    'Drogi',
    'śmierć',
    'dług',
    'grudzień',
    'decyzja',
    'talia kart',
    'głęboko',
    'Pokonać',
    'obrona',
    'stopień',
    'pyszny',
    'Dostawa',
    'popyt',
    'Demon',
    'dział',
    'pustynia',
    'projekt',
    'pragnienie',
    'biurko',
    'zdesperowany',
    'mimo',
    'przeznaczenie',
    'zniszczenie',
    'Szczegół',
    'Detale',
    'detektyw',
    'rozwój',
    'urządzenie',
    'diabeł',
    'diament',
    'Dziennik',
    'kutas',
    'umierać',
    'dieta',
    'różnica',
    'kopać',
    'kolacja',
    'kierunek',
    'dyrektor',
    'brud',
    'katastrofa',
    'choroba',
    'danie',
    'dystans',
    'dzielnica',
    'nurkować',
    'Boska',
    'podział',
    'rozwód',
    'DNA',
    'doktor',
    'lekarz',
    'pies',
    'Lalka',
    'dolar',
    'buc',
    'osioł',
    'drzwi',
    'podwójnie',
    'wątpliwość',
    'na dół',
    'śródmieście',
    'tuzin',
    'ciągnąć',
    'smok',
    'dramat',
    'remis',
    'rysunek',
    'śnić',
    'śnić',
    'sukienka',
    'rysował',
    'drink',
    'picie',
    'prowadzić',
    'kierowca',
    'napędowy',
    'upuść',
    'stado',
    'lek',
    'pijany',
    'suchy',
    'kaczka',
    'koleś',
    'z powodu',
    'książę',
    'wysypisko',
    'pył',
    'obowiązek',
    'umierający',
    'dylana',
    'ucho',
    'Ziemia',
    'łatwość',
    'wschód',
    'jedzenie',
    'jedzenie',
    'gospodarka',
    'krawędź',
    'Edukacja',
    'Edwarda',
    'efekt',
    'efekty',
    'wysiłek',
    'jajko',
    'jajka',
    'osiem',
    'starszy',
    'wybór',
    'elektryczny',
    'Elektryczność',
    'słoń',
    'winda',
    'jedenaście',
    'elżbieta',
    'nagły wypadek',
    'cesarz',
    'imperium',
    'pusty',
    'koniec',
    'kończący się',
    'wróg',
    'energia',
    'silnik',
    'anglia',
    'język angielski',
    'dość',
    'Cały',
    'wejście',
    'środowisko',
    'równy',
    'sprzęt',
    'ucieczka',
    'eskorta',
    'nieruchomość',
    'Europa',
    'europejski',
    'przeddzień',
    'nawet',
    'wieczór',
    'wydarzenie',
    'wszyscy',
    'każdy',
    'dowód',
    'zło',
    'egzamin',
    'przykład',
    'Wymieniać się',
    'wymówka',
    'ćwiczenie',
    'istnienie',
    'Wyjście',
    'doświadczenie',
    'eksperyment',
    'ekspert',
    'wyjaśnienie',
    'eksplozja',
    'wyrazić',
    'wyrażenie',
    'dodatkowy',
    'skrajny',
    'oko',
    'oczy',
    'Twarz',
    'okładzina',
    'fakt',
    'fabryka',
    'niepowodzenie',
    'sprawiedliwy',
    'wróżka',
    'wiara',
    'podróbka',
    'jesień',
    'spada',
    'znajomy',
    'rodzina',
    'miłośnik',
    'wymyślny',
    'Fantazja',
    'daleko',
    'pożegnanie',
    'gospodarstwo rolne',
    'rolnik',
    'moda',
    'szybki',
    'tłuszcz',
    'Los',
    'ojciec',
    'wada',
    'przychylność',
    'ulubiony',
    'przychylność',
    'ulubiony',
    'strach',
    'karmiony',
    'federalny',
    'karmić',
    'karmienie',
    'czuć',
    'uczucie',
    'uczucia',
    'ściąć',
    'koleś',
    'kolega',
    'czułem',
    'Płeć żeńska',
    'ogrodzenie',
    'festiwal',
    'aportować',
    'gorączka',
    'mało',
    'pole',
    'pola',
    'piętnaście',
    'piąty',
    'pięćdziesiąt',
    'bić się',
    'wojownik',
    'walczący',
    'postać',
    'plik',
    'napełnić',
    'film',
    'finał',
    'znajdować',
    'odkrycie',
    'cienki',
    'palec',
    'koniec',
    'ogień',
    'ostrzał',
    'solidny',
    'pierwszy',
    'ryba',
    'Wędkarstwo',
    'pasować',
    'pięć',
    'naprawić',
    'flaga',
    'Lampa błyskowa',
    'mieszkanie',
    'ciało',
    'muchy',
    'lot',
    'piętro',
    'Floryda',
    'pływ',
    'kwiat',
    'latać',
    'latający',
    'Centrum',
    'ludzie',
    'Następny',
    'jedzenie',
    'oszukać',
    'stopa',
    'piłka nożna',
    'dla',
    'zmuszać',
    'Las',
    'Formularz',
    'dawny',
    'naprzód',
    'fortuna',
    'czterdzieści',
    'Naprzód',
    'znaleziony',
    'cztery',
    'czwarty',
    'lis',
    'rama',
    'Francja',
    'szczery',
    'dziwak',
    'wolny',
    'wolność',
    'zamrażać',
    'zamrażanie',
    'Francuski',
    'piątek',
    'lodówka',
    'przyjaciel',
    'przyjazny',
    'przyjaźń',
    'przód',
    'owoc',
    'pierdolić',
    'skurwielu',
    'pierdolony',
    'paliwo',
    'pełny',
    'zabawa',
    'pogrzeb',
    'śmieszne',
    'meble',
    'przyszły',
    'zdobyć',
    'Hazard',
    'gra',
    'banda',
    'garaż',
    'Śmieci',
    'ogród',
    'Gary',
    'gaz',
    'Brama',
    'bramy',
    'zebrać',
    'wesoły',
    'koło zębate',
    'Ojej',
    'ogólny',
    'Pokolenie',
    'geniusz',
    'pan',
    'Jerzy',
    'Niemiecki',
    'Niemcy',
    'dostwać',
    'dostawanie',
    'duch',
    'ogromny',
    'prezent',
    'dziewczyna',
    'dziewczyna',
    'dawać',
    'dany',
    'dający',
    'zadowolony',
    'szkło',
    'okulary',
    'chwała',
    'bramka',
    'Koza',
    'Bóg',
    'bogini',
    'pójście',
    'złoto',
    'golf',
    'dobry',
    'do widzenia',
    'dobroć',
    'rząd',
    'gubernator',
    'złapać',
    'wdzięk',
    'stopień',
    'wielki',
    'Dziadek',
    'babcia',
    'babcia',
    'dziadek',
    'wnuk',
    'babunia',
    'dotacja',
    'trawka',
    'grób',
    'szary',
    'Świetnie',
    'grecki',
    'Zielony',
    'obrzydliwy',
    'grunt',
    'Grupa',
    'rozwój',
    'gwarancja',
    'strażnik',
    'odgadnąć',
    'Gość',
    'przewodnik',
    'wina',
    'gitara',
    'pistolet',
    'wnętrzności',
    'facet',
    'siłownia',
    'nawyk',
    'Grad',
    'włosy',
    'połowa',
    'sala',
    'młot',
    'han',
    'ręka',
    'uchwyt',
    'ręce',
    'powiesić',
    'wiszące',
    'motek',
    'wydarzenie',
    'szczęście',
    'szkoda',
    'kapelusz',
    'nienawidzić',
    'mieć',
    'przystań',
    'głowa',
    'bół głowy',
    'nagłówek',
    'Kwatera główna',
    'zdrowie',
    'przesłuchanie',
    'serce',
    'kiery',
    'ciepło',
    'niebo',
    'niebiosa',
    'ciężki',
    'Helena',
    'śmigłowiec',
    'piekło',
    'cześć',
    'Wsparcie',
    'porcja jedzenia',
    'henz',
    'tutaj',
    'bohater',
    'ukryć',
    'ukrywanie',
    'wysoka',
    'wygórowanie',
    'Autostrada',
    'Wzgórze',
    'biodro',
    'wynajmować',
    'historia',
    'uderzyć',
    'Hitler',
    'uderzanie',
    'utrzymać',
    'trzymać',
    'otwór',
    'wakacje',
    'Hollywood',
    'święty',
    'Dom',
    'Praca domowa',
    'kochanie',
    'Miesiąc miodowy',
    'honor',
    'honor',
    'kaptur',
    'hak',
    'chmiel',
    'mieć nadzieję',
    'klakson',
    'koń',
    'szpital',
    'gospodarz',
    'zakładnik',
    'hotel',
    'godzina',
    'godziny',
    'Dom',
    'Howard',
    'uścisk',
    'człowiek',
    'ludzkość',
    'ludzie',
    'sto',
    'polowanie',
    'myśliwy',
    'polowanie',
    'spieszyć się',
    'zraniony',
    'boli',
    'mąż',
    'lód',
    'pomysł',
    'tożsamość',
    'idiota',
    'chory',
    'choroba',
    'obraz',
    'wyobraźnia',
    'uderzenie',
    'niemożliwy',
    'wrażenie',
    'cal',
    'incydent',
    'zwiększać',
    'Indie',
    'indyjski',
    'indywidualny',
    'przemysł',
    'wpływ',
    'Informacja',
    'niewinny',
    'w środku',
    'inspektor',
    'instancja',
    'zniewaga',
    'ubezpieczenie',
    'inteligencja',
    'zainteresowanie',
    'Internet',
    'przerwać',
    'wywiad',
    'zaproszenie',
    'Zapraszam',
    'Irak',
    'żelazo',
    'wyspa',
    'Izrael',
    'sprawa',
    'Włoski',
    'Włochy',
    'Jacek',
    'kurtka',
    'Jackson',
    'Jakub',
    'więzienie',
    'dżem',
    'James',
    'Jan',
    'Japonia',
    'język japoński',
    'jason',
    'sójka',
    'drelich',
    'przędzarka',
    'szarpać',
    'nocnik',
    'golf',
    'Jezus',
    'strumień',
    'Żyd',
    'Jimmy',
    'stanowisko',
    'Jan',
    'Jasio',
    'johnson',
    'Przystąp',
    'połączenie',
    'żart',
    'Jones',
    'Jordania',
    'Józef',
    'dziennikarz',
    'podróż',
    'radość',
    'sędzia',
    'sok',
    'lipiec',
    'skok',
    'skoki',
    'czerwiec',
    'jung',
    'dżungla',
    'junior',
    'graty',
    'jury',
    'sprawiedliwość',
    'Karen',
    'trzymać',
    'konserwacja',
    'Kelly',
    'rozpoznać',
    'klucz',
    'kopnięcie',
    'kopanie',
    'dziecko',
    'zabić',
    'zabójca',
    'zabicie',
    'uprzejmy',
    'król',
    'Królestwo',
    'pocałunek',
    'całowanie',
    'kuchnia',
    'koteczek',
    'kolano',
    'nóż',
    'pukanie',
    'pukanie',
    'wiedzieć',
    'porozumiewawczy',
    'wiedza',
    'Korea',
    'koreański',
    'laboratorium',
    'brak',
    'chłopak',
    'dama',
    'jezioro',
    'grunt',
    'lądowanie',
    'język',
    'wielki',
    'trwać',
    'najnowszy',
    'łacina',
    'śmiech',
    'śmiech',
    'początek',
    'pranie',
    'prawo',
    'prawa',
    'prawnik',
    'kłaść',
    'Ołów',
    'lider',
    'liderzy',
    'prowadzący',
    'liga',
    'uczenie się',
    'najmniej',
    'wychodzić',
    'odjazd',
    'prowadzony',
    'zawietrzny',
    'lewy',
    'noga',
    'legenda',
    'nogi',
    'Lew',
    'lekcja',
    'pozwolić',
    'list',
    'listy',
    'pozwalając',
    'poziom',
    'kłamca',
    'Biblioteka',
    'licencja',
    'kłamać',
    'kłamał',
    'porucznik',
    'życie',
    'dożywotni',
    'wyciąg',
    'lekki',
    'zapalniczka',
    'Błyskawica',
    'tak jak',
    'lilia',
    'limit',
    'linia',
    'molwa',
    'połączyć',
    'Lew',
    'trunek',
    'lista',
    'słuchający',
    'mało',
    'wątroba',
    'żyjący',
    'Załaduj',
    'pożyczka',
    'lokalny',
    'Lokalizacja',
    'Zamek',
    'Londyn',
    'dłuższy',
    'Popatrz',
    'patrząc',
    'Lord',
    'przegrany',
    'strata',
    'Stracony',
    'działka',
    'wiele',
    'Ludwik',
    'miłość',
    'śliczny',
    'kochanek',
    'Niska',
    'niżej',
    'szczęście',
    'Lucy',
    'Łukasz',
    'obiad',
    'kłamliwy',
    'prochowiec',
    'maszyna',
    'Szanowna Pani',
    'pani',
    'szaleństwo',
    'czasopismo',
    'magia',
    'Pokojówka',
    'Poczta',
    'Główny',
    'majestat',
    'poważny',
    'robić',
    'zrobienie',
    'mężczyzna',
    'Centrum handlowe',
    'mama',
    'Człowiek',
    'menedżer',
    'mapa',
    'Marsz',
    'Maria',
    'znak',
    'rynek',
    'znaki',
    'małżeństwo',
    'żonaty',
    'Mars',
    'wojenny',
    'jaskółka oknówka',
    'mary',
    'maska',
    'masa',
    'masaż',
    'mistrz',
    'mecz',
    'kumpel',
    'materiał',
    'matematyka',
    'matowy',
    'materiał',
    'maks',
    'może',
    'majowie',
    'burmistrz',
    'posiłek',
    'mieć na myśli',
    'oznaczający',
    'oznacza',
    'mięso',
    'medyczny',
    'lek',
    'Medycyna',
    'poznać',
    'spotkanie',
    'członek',
    'pamięć',
    'mężczyźni',
    'wzmianka',
    'Łaska',
    'bałagan',
    'wiadomość',
    'metal',
    'metoda',
    'Meksyk',
    'Michał',
    'mickey',
    'środkowy',
    'środek nocy',
    'móc',
    'mikrofon',
    'Mila',
    'wojskowy',
    'mleko',
    'milion',
    'min',
    'umysł',
    'mój',
    'minister',
    'drobny',
    'minuta',
    'minuty',
    'cud',
    'lustro',
    'tęsknić',
    'misja',
    'błąd',
    'pan',
    'kochanka',
    'mieszać',
    'mobilny',
    'Model',
    'nowoczesny',
    'pedał',
    'mama',
    'za chwilę',
    'mamusia',
    'pon',
    'poniedziałek',
    'pieniądze',
    'mnich',
    'małpa',
    'lord',
    'potwór',
    'miesiąc',
    'nastrój',
    'księżyc',
    'morał',
    'jeszcze',
    'morgana',
    'rano',
    'idiota',
    'Moskwa',
    'mama',
    'skurwysynu',
    'ruch',
    'Góra',
    'mysz',
    'usta',
    'ruszaj się',
    'ruch',
    'film',
    'pani',
    'wiele',
    'błoto',
    'milczący',
    'Bombaj',
    'mumia',
    'morderstwo',
    'morderca',
    'muzeum',
    'muzyka',
    'musi',
    'tajemnica',
    'gwóźdź',
    'imię',
    'nazwy',
    'narrator',
    'naród',
    'krajowy',
    'naturalny',
    'Natura',
    'niezbędny',
    'szyja',
    'potrzebować',
    'negatywny',
    'sąsiad',
    'sąsiedztwo',
    'siostrzeniec',
    'nerw',
    'nerwowość',
    'Internet',
    'sieć',
    'Aktualności',
    'Gazeta',
    'Ładny',
    'nacięcie',
    'noc',
    'koszmar',
    'Nina',
    'dziewięć',
    'szlachetny',
    'nikt',
    'hałas',
    'Żaden',
    'nonsens',
    'południe',
    'normalna',
    'północ',
    'nos',
    'Notatka',
    'nic',
    'ogłoszenie',
    'powieść',
    'Teraz',
    'Obecnie',
    'nigdzie',
    'numer',
    'liczby',
    'pielęgniarka',
    'orzech',
    'obiekt',
    'okazja',
    'ocean',
    'październik',
    'oferta',
    'oferując',
    'gabinet',
    'oficer',
    'urzędnik',
    'olej',
    'w porządku',
    'stary',
    'jeden',
    'otwarty',
    'otwarcie',
    'opera',
    'operacja',
    'opinia',
    'możliwość',
    'naprzeciw',
    'opcja',
    'Pomarańczowy',
    'zamówienie',
    'zwyczajny',
    'organizacja',
    'oryginalny',
    'Oscar',
    'na zewnątrz',
    'strój',
    'na zewnątrz',
    'koniec',
    'właściciel',
    'tlen',
    'Pakiet',
    'pakiet',
    'strona',
    'ból',
    'farba',
    'obraz',
    'para',
    'kumpel',
    'pałac',
    'patelnia',
    'panika',
    'dysząc',
    'Spodnie',
    'papa',
    'papier',
    'dokumenty tożsamości',
    'raj',
    'pardon',
    'Paryż',
    'park',
    'parking',
    'część',
    'konkretny',
    'partner',
    'Części',
    'impreza',
    'podawać',
    'przechodzący',
    'pasja',
    'paszport',
    'przeszłość',
    'ścieżka',
    'cierpliwość',
    'pacjent',
    'Patrick',
    'Paweł',
    'płacić',
    'Zapłata',
    'pokój',
    'robić siku',
    'długopis',
    'penis',
    'grosz',
    'ludzie',
    'procent',
    'idealny',
    'występ',
    'Kropka',
    'pozwolenie',
    'osoba',
    'osobisty',
    'osobowość',
    'zboczeniec',
    'zwierzak domowy',
    'Piotr',
    'telefon',
    'zdjęcie',
    'fotografia',
    'fotograf',
    'fortepian',
    'wybierać',
    'owocobranie',
    'zdjęcie',
    'ciasto',
    'Sztuka',
    'pierre',
    'świnia',
    'pigułka',
    'pilot',
    'różowy',
    'rura',
    'siki',
    'poziom',
    'szkoda',
    'Pizza',
    'miejsce',
    'Równina',
    'plan',
    'samolot',
    'planeta',
    'planowanie',
    'Zakład',
    'Plastikowy',
    'talerz',
    'bawić się',
    'gracz',
    'gra',
    'przyjemność',
    'mnóstwo',
    'plus',
    'kieszeń',
    'wiersz',
    'poeta',
    'poezja',
    'punkt',
    'zatruć',
    'Polak',
    'Policja',
    'policjant',
    'polityka',
    'Polski',
    'Polityka',
    'basen',
    'słaby',
    'Muzyka pop',
    'Papież',
    'populacja',
    'mięso wieprzowe',
    'porno',
    'Port',
    'pozycja',
    'pozytywny',
    'możliwość',
    'możliwy',
    'Poczta',
    'garnek',
    'Ziemniak',
    'potencjał',
    'funt',
    'proszek',
    'moc',
    'ćwiczyć',
    'pochwała',
    'modlitwa',
    'obecność',
    'obecny',
    'prezydent',
    'naciskać',
    'ciśnienie',
    'udawać',
    'udając',
    'Cena £',
    'ukłucie',
    'duma',
    'kapłan',
    'główny',
    'książę',
    'księżniczka',
    'dyrektor',
    'wydrukować',
    'więzienie',
    'więzień',
    'prywatny',
    'nagroda',
    'zawodowiec',
    'problem',
    'proces',
    'produkować',
    'producent',
    'produkt',
    'produkcja',
    'profesjonalny',
    'profesor',
    'zysk',
    'program',
    'postęp',
    'projekt',
    'obietnica',
    'dowód',
    'własność',
    'wniosek',
    'ochrona',
    'psychika',
    'publiczny',
    'ciągnąć',
    'ciągnąc',
    'puls',
    'dziurkacz',
    'kara',
    'punk',
    'cel, powód',
    'portmonetka',
    'naciskać',
    'popychanie',
    'kiciuś',
    'wkładać',
    'kładzenie',
    'jakość',
    'jedna czwarta',
    'królowa',
    'pytanie',
    'szybki',
    'spokojny',
    'Królik',
    'wyścigi',
    'Rachela',
    'radio',
    'deszcz',
    'podnieść',
    'raj',
    'Baran',
    'zakres',
    'rzepak',
    'szczur',
    'wskaźnik',
    'promień',
    'zasięg',
    'reakcja',
    'czytać',
    'czytanie',
    'gotowy',
    'prawdziwy',
    'rzeczywistość',
    'powód',
    'przypomnienie sobie czegoś',
    'ostatni',
    'nagrywać',
    'nagranie',
    'czerwony',
    'odrzucać',
    'rejestr',
    'żal',
    'regularny',
    'relacja',
    'uwolnienie',
    'ulga',
    'religia',
    'religijny',
    'pozostaje',
    'zdalny',
    'usunąć',
    'wynajem',
    'powtarzać',
    'raport',
    'reporter',
    'reputacja',
    'prośba',
    'ratować',
    'badania',
    'Poszanowanie',
    'odpowiedź',
    'odpoczynek',
    'restauracja',
    'wynik',
    'powrót',
    'Zemsta',
    'rewolucja',
    'nagroda',
    'Ryż',
    'bogaty',
    'stóg',
    'jazda',
    'jazda konna',
    'prawidłowy',
    'pierścień',
    'dzwonienie',
    'pierścionki',
    'rozerwać',
    'wzrastać',
    'ryzyko',
    'rzeka',
    'droga',
    'drogi',
    'rozbój',
    'Robert',
    'robot',
    'głaz',
    'rakieta',
    'rola',
    'rolka',
    'walcowanie',
    'rzymski',
    'romans',
    'romantyk',
    'Rzym',
    'ron',
    'dach',
    'Pokój',
    'pokoje',
    'lina',
    'Róża',
    'surowy',
    'okrągły',
    'trasa',
    'rutyna',
    'wiersz',
    'królewski',
    'pocierać',
    'śmieci',
    'ruina',
    'reguła',
    'biegać',
    'działanie',
    'pośpiech',
    'Rosja',
    'Rosyjski',
    'worek',
    'poświęcać się',
    'bezpieczny',
    'bezpieczeństwo',
    'święty',
    'wzgląd',
    'Sałatka',
    'pensja',
    'wyprzedaż',
    'sprzedaż',
    'wypad',
    'Sól',
    'Sama',
    'to samo',
    'próbka',
    'piasek',
    'kanapka',
    'śpiewał',
    'Święty',
    'Sarah',
    'sat',
    'satelita',
    'sobota',
    'sos',
    'zapisać',
    'oszczędność',
    'widział',
    'mówić',
    'powiedzenie',
    'skala',
    'przestraszyć',
    'scena',
    'harmonogram',
    'Szkoła',
    'nauki ścisłe',
    'naukowiec',
    'wynik',
    'Scott',
    'zadrapanie',
    'krzyk',
    'krzyczeć',
    'ekran',
    'śruba',
    'scenariusz',
    'morze',
    'foka',
    'Szukaj',
    'pora roku',
    'siedziba',
    'siedzenia',
    'sek',
    'druga',
    'sekret',
    'sekretarz',
    'Sekcja',
    'bezpieczeństwo',
    'zobaczyć',
    'widzenie',
    'szukać',
    'samego siebie',
    'Sprzedać',
    'sprzedawanie',
    'senator',
    'wysyłanie',
    'senior',
    'sens',
    'wrażliwy',
    'wysłano',
    'wyrok',
    'oddzielny',
    'wrzesień',
    'sierżant',
    'seria',
    'sługa',
    'podawać',
    'usługa',
    'usługi',
    'ustawić',
    'ustawienie',
    'rozstrzygać',
    'siedem',
    'seks',
    'cień',
    'potrząsnąć',
    'drżący',
    'wstyd',
    'kształt',
    'dzielić',
    'rekin',
    'ostry',
    'Szopa',
    'owce',
    'muszla',
    'schron',
    'szeryf',
    'Zmiana',
    'połysk',
    'statek',
    'koszula',
    'gówno',
    'zaszokować',
    'but',
    'obuwie',
    'strzelać',
    'strzelanie',
    'sklep',
    'zakupy',
    'Wybrzeże',
    'krótki',
    'strzał',
    'ramię',
    'krzyczeć',
    'rozkrzyczany',
    'pokazać',
    'kabina prysznicowa',
    'seans',
    'nieśmiały',
    'chory',
    'bok',
    'widok',
    'podpisać',
    'sygnał',
    'podpis',
    'cisza',
    'głupi',
    'srebro',
    'Szymon',
    'prosty',
    'grzech',
    'piosenkarz',
    'śpiewanie',
    'pojedynczy',
    'tonąć',
    'Pan',
    'siostra',
    'siostra',
    'teren',
    'posiedzenie',
    'sytuacja',
    'sześć',
    'rozmiar',
    'skóra',
    'chudy',
    'pomijać',
    'czaszka',
    'niebo',
    'policzkować',
    'niewolnik',
    'spać',
    'spanie',
    'ślizgać się',
    'poślizg',
    'chlapa',
    'mały',
    'mądry',
    'zapach',
    'uśmiechać się',
    'uśmiechnięty',
    'kowal',
    'palić',
    'palenie',
    'gładki',
    'wąż',
    'pstryknąć',
    'śnieg',
    'mydło',
    'szlochanie',
    'piłka nożna',
    'społeczny',
    'społeczeństwo',
    'żołnierz',
    'solidny',
    'rozwiązanie',
    'ktoś',
    'ktoś',
    'coś',
    'gdzieś',
    'syn',
    'piosenka',
    'wcześniej',
    'sortować',
    'dusza',
    'dźwięk',
    'zupa',
    'źródło',
    'południe',
    'przestrzeń',
    'Hiszpania',
    'hiszpański',
    'zapasowy',
    'mówienie',
    'specjalny',
    'gatunki',
    'konkretny',
    'przemówienie',
    'prędkość',
    'zaklęcie',
    'wydatki',
    'pająk',
    'kręcić się',
    'duch',
    'duchy',
    'pluć',
    'rozdzielać',
    'przemówił',
    'sport',
    'miejsce',
    'rozprzestrzeniać się',
    'wiosna',
    'szpieg',
    'Drużyna',
    'kwadrat',
    'ściskać',
    'personel',
    'scena',
    'schody',
    'stawka',
    'stoisko',
    'standard',
    'na stojąco',
    'gwiazda',
    'gapić się',
    'początek',
    'startowy',
    'głodujący',
    'stan',
    'oświadczenie',
    'stacja',
    'status',
    'zostawać',
    'gorset',
    'stały',
    'skraść',
    'kradzież',
    'stal',
    'krok',
    'Stephen',
    'kroki',
    'kij',
    'Nadal',
    'smród',
    'Zbiory',
    'Ukradłem',
    'brzuch',
    'kamień',
    'zatrzymać',
    'zatrzymanie',
    'zatrzymuje się',
    'sklep',
    'burza',
    'fabuła',
    'proste',
    'nieznajomy',
    'ulica',
    'siła',
    'stres',
    'rozciągać',
    'strajk',
    'rozebrać się',
    'walka',
    'student',
    'studio',
    'badanie',
    'uczenie się',
    'rzeczy',
    'głupi',
    'styl',
    'Przedmiot',
    'sukces',
    'ssać',
    'pozwać',
    'cierpienie',
    'cukier',
    'samobójstwo',
    'garnitur',
    'walizka',
    'lato',
    'słońce',
    'niedziela',
    'zaśpiewany',
    'światło słoneczne',
    'Wspaniały',
    'kolacja',
    'dostarczać',
    'Pomoc',
    'powierzchnia',
    'Chirurgia',
    'niespodzianka',
    'poddanie się',
    'podejrzany',
    'Łyk',
    'pocić się',
    'Słodkie',
    'kochanie',
    'kochanie',
    'pływać',
    'pływanie',
    'huśtać się',
    'przełącznik',
    'miecz',
    'symbol',
    'system',
    'stół',
    'ogon',
    'Brać',
    'nabierający',
    'opowieść',
    'talent',
    'rozmowa',
    'rozmawiając',
    'rozmowy',
    'wysoki',
    'zbiornik',
    'uzyskiwać',
    'taśma',
    'cel',
    'zadanie',
    'smak',
    'tatuaż',
    'podatek',
    'Taxi',
    'Taylor',
    'herbata',
    'nauczać',
    'nauczyciel',
    'nauczanie',
    'zespół',
    'łza',
    'łzy',
    'technologia',
    'przetrząsać',
    'Miś',
    'zęby',
    'telefon',
    'telewizja',
    'powiedzieć',
    'wymowny',
    'temperatura',
    'Świątynia',
    'dziesięć',
    'termin',
    'warunki',
    'terytorium',
    'terrorysta',
    'frotte',
    'test',
    'testowanie',
    'Teksas',
    'tekst',
    'dzięki',
    'teatr',
    'teatr',
    'temat',
    'następnie',
    'teoria',
    'terapia',
    'tam',
    'gruby',
    'złodziej',
    'rzecz',
    'rzeczy',
    'myśleć',
    'myślący',
    'trzeci',
    'trzydzieści',
    'Tomasz',
    'ty',
    'myśl',
    'tysiąc',
    'groźba',
    'trzy',
    'gardło',
    'rzucić',
    'grzmot',
    'Czwartek',
    'zatem',
    'bilet',
    'wiązanie',
    'Tygrys',
    'do',
    'czas',
    'czasy',
    'wyczucie czasu',
    'Wskazówka',
    'tytuł',
    'toast',
    'Dziś',
    'toaleta',
    'Tokio',
    'Tomek',
    'jutro',
    'ton',
    'język',
    'tej nocy',
    'mnóstwo',
    'ząb',
    'najlepszy',
    'torturować',
    'całkowity',
    'dotykać',
    'wzruszające',
    'trudny',
    'wycieczka',
    'ręcznik',
    'wieża',
    'miasto',
    'zabawka',
    'namierzać',
    'tor',
    'handel',
    'tradycja',
    'ruch drogowy',
    'tragedia',
    'pociąg',
    'szkolenie',
    'zdrajca',
    'przenosić',
    'pułapka',
    'śmieci',
    'podróż',
    'podróżny',
    'Skarb',
    'fundować',
    'leczenie',
    'drzewo',
    'test',
    'sztuczka',
    'spust',
    'wycieczka',
    'wojsko',
    'kłopoty',
    'samochód ciężarowy',
    'prawda',
    'bagażnik samochodowy',
    'zaufanie',
    'prawda',
    'próbować',
    'Wtorek',
    'melodia',
    'tunel',
    'indyk',
    'skręcać',
    'obrócenie',
    'dwanaście',
    '20',
    'Bliźnięta',
    'dwa',
    'rodzaj',
    'Wujek',
    'pod ziemią',
    'Bielizna',
    'mundur',
    'unia',
    'jednostka',
    'wszechświat',
    'Uniwersytet',
    'nieznany',
    'górny',
    'smutny',
    'na górę',
    'stosowanie',
    'za pomocą',
    'wakacje',
    'Dolina',
    'cenny',
    'wartość',
    'wampir',
    'awangarda',
    'pojazd',
    'wersja',
    'wice',
    'ofiara',
    'zwycięzca',
    'zwycięstwo',
    'wideo',
    'pogląd',
    'wioska',
    'przemoc',
    'dziewica',
    'wirus',
    'wizja',
    'odwiedzić',
    'przyjezdny',
    'wódka',
    'głos',
    'głosować',
    'czekać',
    'Czekanie',
    'budzić',
    'spacerować',
    'pieszy',
    'Ściana',
    'portfel',
    'Walter',
    'chcieć',
    'wojna',
    'ostrzeżenie',
    'nakaz',
    'wojownik',
    'myć się',
    'mycie',
    'Waszyngton',
    'marnować',
    'zmarnowanie',
    'obserwować',
    'oglądanie',
    'woda',
    'fale',
    'fala',
    'sposób',
    'Wayne',
    'sposoby',
    'bogactwo',
    'broń',
    'nosić',
    'ma na sobie',
    'pogoda',
    'ślub',
    'Środa',
    'chwast',
    'tydzień',
    'weekend',
    'waga',
    'weil',
    'dziwny',
    'Witamy',
    'dobrze',
    'zachód',
    'Zachodni',
    'mokry',
    'Co',
    'koło',
    'dopóki',
    'szeptanie',
    'gwizdać',
    'biały',
    'WHO',
    'cały',
    'kurwa',
    'czemu',
    'żona',
    'dziki',
    'będzie',
    'chętny',
    'Wilsona',
    'wygrać',
    'wiatr',
    'okno',
    'okna',
    'wino',
    'skrzydło',
    'skrzydełka',
    'zwycięzca',
    'zwycięski',
    'zima',
    'wytrzeć',
    'drut',
    'mądry',
    'życzenie',
    'czarownica',
    'świadek',
    'Wilk',
    'kobieta',
    'wygrała',
    'cud',
    'drewno',
    'Las',
    'słowo',
    'słowa',
    'Praca',
    'pracownik',
    'pracujący',
    'Pracuje',
    'świat',
    'niepokoić się',
    'niepokojący',
    'gorzej',
    'najgorszy',
    'wartość',
    'rana',
    'ranny',
    'Wow',
    'zawinąć',
    'pisarz',
    'pismo',
    'zło',
    'www',
    'dziedziniec',
    'rok',
    'lat',
    'krzyk',
    'wrzeszczał',
    'żółty',
    'jen',
    'TAk',
    'wczoraj',
    'jork',
    'młody',
    'młodzież',
    'zero',
    'strefa',
    'opuszczony',
    'zdolny',
    'o',
    'za granicą',
    'absurdalny',
    'przyjęty',
    'według',
    'as',
    'aktywny',
    'rzeczywisty',
    'przyjęty',
    'zaawansowane',
    'przestraszony',
    'po',
    'agresywny',
    'temu',
    'Zgoda',
    'dalej',
    'zarówno',
    'żywy',
    'wszystko',
    'sam',
    'alfa',
    'w porządku',
    'niesamowity',
    'zły',
    'inne',
    'odpowiadam',
    'niespokojny',
    'każdy',
    'oprócz',
    'właściwy',
    'uzbrojony',
    'ułożone',
    'zawstydzony',
    'we śnie',
    'przywiązany',
    'atrakcyjny',
    'do dyspozycji',
    'obudzony',
    'świadomy',
    'z dala',
    'świetny',
    'okropny',
    'niezręczny',
    'łysy',
    'na podstawie',
    'bas',
    'bity',
    'Piękny',
    'twarzowy',
    'duża',
    'większy',
    'pusty',
    'koc',
    'błogosławiony',
    'zablokowany',
    'blond',
    'krwawy',
    'nadęty',
    'zarezerwowane',
    'znudzony',
    'Zarówno',
    'zaniepokojony',
    'krótki',
    'jasny',
    'znakomity',
    'szeroki',
    'złamał',
    'złamany',
    'wybudowany',
    'pochowany',
    'spalony',
    'spalony',
    'złapany',
    'zajęty',
    'zdolny',
    'ostrożny',
    'uchwyt',
    'niektórzy',
    'zmieniony',
    'wymiana pieniędzy',
    'naładowany',
    'uroczy',
    'tani',
    'sprawdzony',
    'chemiczny',
    'wiśnia',
    'cywilny',
    'wyczyszczone',
    'mądry',
    'Zamknięte',
    'wygodna',
    'zobowiązany',
    'komunistyczny',
    'narzekać',
    'kompletny',
    'zakończony',
    'skomplikowany',
    'zainteresowany',
    'pewni',
    'Potwierdzony',
    'zmieszany',
    'połączony',
    'uważane',
    'zawartość',
    'nieprzerwany',
    'przeciwnie',
    'kontrolowane',
    'przekonany',
    'gotowany',
    'prawidłowy',
    'pokryty',
    'twórczy',
    'dziwny',
    'krytyczny',
    'skrzyżowane',
    'okrutny',
    'ciekawski',
    'przeklęty',
    'Słodki',
    'uszkodzony',
    'niebezpieczny',
    'śmiertelnie',
    'przyzwoity',
    'zdecydowany',
    'pokonany',
    'delikatny',
    'w depresji',
    'zasłużony',
    'zaprojektowany',
    'zniszczony',
    'ustalona',
    'rozwinięty',
    'inny; różny',
    'trudny',
    'bezpośredni',
    'skierowany',
    'brudny',
    'zawiedziony',
    'odkryty',
    'obrzydliwe',
    'odległy',
    'niepokojący',
    'rozwiedziony',
    'gotowy',
    'na dół',
    'dramatyczny',
    'pociągnięty',
    'marzyłem',
    'ubrany',
    'napędzany',
    'rzut',
    'głupi',
    'każdy',
    'wcześniej',
    'wcześnie',
    'zarobione',
    'wschodni',
    'łatwo',
    'gospodarczy',
    'wykształcony',
    'efektywny',
    'Zakłopotany',
    'żenujący',
    'emocjonalny',
    'zakończone',
    'zaręczony',
    'ogromny',
    'uciekł',
    'wieczny',
    'każdy',
    'codzienny',
    'dokładny',
    'doskonały',
    'podekscytowany',
    'ekscytujący',
    'wyczerpany',
    'spodziewany',
    'drogi',
    'doświadczony',
    'narażony',
    'wspaniały',
    'w obliczu',
    'upadły',
    'spadający',
    'fałszywe',
    'słynny',
    'fantastyczny',
    'fascynujący',
    'wzorzysty',
    'wypełniony',
    'brudny',
    'budżetowy',
    'skończone',
    'zwolniony z pracy',
    'naprawiony',
    'trzepnięcie',
    'Ruchomy',
    'skoncentrowany',
    'czuły',
    'oszukiwać',
    'niemądry',
    'zakazany',
    'wymuszony',
    'zagraniczny',
    'zapomniany',
    'uformowany',
    'faul',
    'świeży',
    'smażony',
    'przerażony',
    'mrożony',
    'dalej',
    'Zebrane',
    'hojny',
    'delikatny',
    'światowy',
    'przeklęty',
    'złoty',
    'stracony',
    'przepiękny',
    'absolwent',
    'nadany',
    'wdzięczny',
    'największy',
    'szary',
    'dorosły',
    'winny',
    'wręczony',
    'przystojny',
    'szczęśliwy',
    'ciężko',
    'szorstki',
    'znienawidzony',
    'głowiasty',
    'zdrowy',
    'słyszał',
    'ukryty',
    'wyższy',
    'zatrudniony',
    'bezdomny',
    'uczciwy',
    'haczykowaty',
    'beznadziejny',
    'seksualnie podniecony',
    'straszny',
    'gorąco',
    'olbrzymi',
    'głodny',
    'ideał',
    'nielegalny',
    'ważny',
    'pod wrażeniem',
    'robi wrażenie',
    'niesamowite',
    'niezależny',
    'niewyraźny',
    'zainfekowany',
    'powiadomiony',
    'ranny',
    'wewnętrzny',
    'obłąkany',
    'natchniony',
    'natychmiastowy',
    'inteligentny',
    'intensywny',
    'zainteresowany',
    'ciekawe',
    'wewnętrzny',
    'niewidzialny',
    'zaangażowany',
    'zazdrosny',
    'żydowski',
    'Dołączył',
    'żartuje',
    'tylko',
    'trzymane',
    'znany',
    'położony',
    'kiepski',
    'większy',
    'późno',
    'później',
    'śmiać się',
    'leniwy',
    'nauczyli',
    'prawny',
    'lesbijka',
    'mniej',
    'lubię',
    'prawdopodobne',
    'ograniczony',
    'oświetlony',
    'relacja na żywo',
    'załadowany',
    'samotny',
    'długi',
    'luźny',
    'głośny',
    'parszywy',
    'kochany',
    'kochający',
    'wierny',
    'mający szczęście',
    'szalony',
    'zrobiony',
    'magiczny',
    'wspaniały',
    'wiele',
    'morski',
    'masywny',
    'psychiczny',
    'radosny',
    'Środek',
    'potężny',
    'mini',
    'nieszczęśliwy',
    'pominięty',
    'zaginiony',
    'błędny',
    'mieszany',
    'bardzo',
    'silnik',
    'przeniósł',
    'poruszający',
    'zamordowany',
    'musical',
    'muzułmański',
    'tajemniczy',
    'naiwny',
    'nagi',
    'paskudny',
    'niegrzeczny',
    'nazi',
    'Blisko',
    'w pobliżu',
    'potrzebne',
    'ani',
    'nerwowy',
    'Nowy',
    'Następny',
    'północny',
    'zauważony',
    'jądrowy',
    'orzechy',
    'obsesję',
    'oczywiste',
    'dziwne',
    'wyłączony',
    'starszy',
    'online',
    'tylko',
    'otwierany',
    'zamówiony',
    'zorganizowany',
    'inny',
    'Inaczej',
    'posiadać',
    'posiadane',
    'zapakowane',
    'płatny',
    'bolesny',
    'namalowany',
    'poklepać',
    'żałosne',
    'intratny',
    'spokojna',
    'fizyczny',
    'zalany',
    'umieszczony',
    'zaplanowany',
    'grał',
    'przyjemny',
    'zadowolony',
    'grzeczny',
    'polityczny',
    'popularny',
    'potężny',
    'cenny',
    'w ciąży',
    'przygotowany',
    'ładny',
    'Poprzedni',
    'właściwy',
    'chroniony',
    'ochrona',
    'dumny',
    'opublikowany',
    'ukarany',
    'czysty',
    'pada',
    'uniesiony',
    'wychowywanie',
    'losowy',
    'krzykliwy',
    'zgwałcony',
    'rzadko spotykany',
    'surowy',
    'realizowany',
    'tył',
    'rozsądny',
    'Odebrane',
    'rozpoznany',
    'nagrany',
    'odzyskany',
    'związane z',
    'względny',
    'REMOVED',
    'zgłoszone',
    'wymagany',
    'odpowiedzialny',
    'emerytowany',
    'powracający',
    'odwracać',
    'niedorzeczny',
    'podarte',
    'rosnący',
    'zgniły',
    'guma',
    'rubin',
    'niegrzeczny',
    'zrujnowany',
    'poświęcony',
    'smutny',
    'powiedział',
    'piaszczysty',
    'zadowolona',
    'zapisane',
    'przestraszony',
    'straszny',
    'naukowy',
    'badawczy',
    'bezpieczne',
    'samolubny',
    'rozdzielony',
    'seryjny',
    'poważny',
    'zadomowiony',
    'kilka',
    'seksualny',
    'seksowny',
    'wspólny',
    'dzielenie się',
    'świecący',
    'gówniany',
    'wstrząśnięty',
    'zamknąć',
    'podpisany',
    'cichy',
    'podobny',
    'szósty',
    'powolny',
    'mniejszy',
    'skradać się',
    'miękki',
    'sprzedany',
    'solo',
    'rozwiązany',
    'niektóre',
    'czasami',
    'przepraszam',
    'południowy',
    'zużyty',
    'duchowy',
    'Mówiony',
    'stabilny',
    'gapiowski',
    'klejący',
    'zatrzymany',
    'dziwne',
    'mocny',
    'uderzony',
    'uparty',
    'zablokowany',
    'badane',
    'udany',
    'taki',
    'nagły',
    'słoneczny',
    'znakomity',
    'przypuszczalny',
    'najwyższy',
    'Jasne',
    'zaskoczony',
    'otoczony',
    'podejrzany',
    'zajęty',
    'utalentowany',
    'smaczny',
    'tymczasowy',
    'napięty',
    'straszny',
    'przerażający',
    'przetestowany',
    'tajski',
    'chudy',
    'spragniony',
    'zagrożony',
    'groźny',
    'poprzez',
    'rzucony',
    'zawiązany',
    'obcisły',
    'malutki',
    'zmęczony',
    'razem',
    'rozdarty',
    'wzruszony',
    'w kierunku',
    'tradycyjny',
    'tragiczny',
    'przeszkolony',
    'uwięziony',
    'leczony',
    'wypróbowany',
    'zaufany',
    'próbować',
    'obrócony',
    'bliźniak',
    'skręcone',
    'typowy',
    'brzydki',
    'najwyższy',
    'niezdolny',
    'nie do wiary',
    'nieświadomy',
    'pod',
    'zrozumiany',
    'niesprawiedliwy',
    'nieszczęśliwy',
    'unikalny',
    'zjednoczony',
    'w odróżnieniu',
    'niezwykły',
    'pilne',
    'używany',
    'użyteczne',
    'nieprzydatny',
    'zwykły',
    'próżny',
    'zniknął',
    'różny',
    'rozległy',
    'bardzo',
    'brutalny',
    'chciał',
    'brakujący',
    'ciepły',
    'ogrzewanie',
    'umyty',
    'zmarnowany',
    'słaby',
    'maleńki',
    'cokolwiek',
    'podły',
    'szeroki',
    'czarodziej',
    'wspaniały',
    'zastanawianie się',
    'noszony',
    'zmartwiony',
    'Bezwartościowy',
    'godny',
    'owinięty',
    'pisemny',
    'Młodszy'
  ]
};
