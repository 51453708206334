
  import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
  import firebase from 'firebase';
  import { useI18n } from 'vue-i18n';

  import useToast from '@/composables/use-toast';
  import BoardOutside from '@/components/BoardOutside.vue';
  import BoardCard from '@/components/BoardCard.vue';
  import BaseBrandingItem from '@/components/common/BaseBrandingItem.vue';
  import BoardCardDescription from '@/components/BoardCardDescription.vue';
  import BaseTeamCard from '@/components/BaseTeamCard.vue';
  import BaseChanceCard from '@/components/BaseChanceCard.vue';
  import BaseOfficeCard from '@/components/BaseOfficeCard.vue';
  import BaseZusCard from '@/components/BaseZusCard.vue';
  import BaseCornerCard from '@/components/BaseCornerCard.vue';
  import BaseIndividualCard from '@/components/BaseIndividualCard.vue';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';
  import { Card, CardUpgradeConfig } from '@/interfaces/businessgame';
  import { BUYABLE_CARDS, CARD_TYPES } from '@/constants';
  // import {
  //   CARDS_BOTTOM,
  //   CARDS_LEFT,
  //   CARDS_RIGHT,
  //   CARDS_TOP
  // } from '@/constants/law';

  const db = firebase.firestore();

  const colors = [
    undefined,
    undefined,
    'pink',
    'brown',
    undefined,
    undefined,
    '#222',
    undefined,
    '#4FBE98',
    'blue',
    undefined,
    'darkgreen',
    undefined,
    'magenta',
    '#0F88CA',
    undefined,
    '#00FF92',
    undefined,
    'orange',
    undefined,
    undefined,
    '#D1938D',
    '#f14668',
    undefined,
    'purple',
    undefined,
    '#5D61FF',
    '#D8DD37',
    undefined,
    'yellow',
    undefined,
    '#009CFF',
    '#48c774',
    undefined,
    '#202A57',
    undefined,
    undefined,
    '#202A57',
    undefined,
    '#202A57'
  ];

  export default defineComponent({
    name: 'SuperUserFunBusinessGameBoards',
    components: {
      BaseButtonWithSpinner,
      BoardOutside,
      BoardCard,
      BaseBrandingItem,
      BoardCardDescription
    },
    setup() {
      const { successToast, errorToast } = useToast();
      const { t } = useI18n();
      const BUYABLE: Ref<string[]> = ref(BUYABLE_CARDS);

      const configs: Ref<any> = ref([]);
      const cardEdited: Ref<undefined | Card> = ref(undefined);
      const cardPreviewed: Ref<undefined | Card> = ref(undefined);
      const boardPreviewed: Ref<any> = ref(null);
      const boardChangedActiveIndex: Ref<number> = ref(0);
      const boardPreviewVisible: Ref<boolean> = ref(false);
      const cardPreviewVisible: Ref<boolean> = ref(false);

      const godModeNewBoardConfig: Ref<string> = ref('law');

      const upgradeConfig: Ref<CardUpgradeConfig> = computed(() => ({
        categoryOne: {
          en: 'categoryOne',
          pl: 'categoryOne'
        },
        categoryTwo: {
          en: 'categoryTwo',
          pl: 'categoryTwo'
        },
        categoryThree: {
          en: 'categoryThree',
          pl: 'categoryThree'
        },
        categoryFour: {
          en: 'categoryFour',
          pl: 'categoryFour'
        }
      }));

      const boardCardComponent = computed(() => {
        switch (cardPreviewed.value?.type) {
          case CARD_TYPES.TEAM:
            return BaseTeamCard;
          case CARD_TYPES.CHANCE:
            return BaseChanceCard;
          case CARD_TYPES.OFFICE:
            return BaseOfficeCard;
          case CARD_TYPES.ZUS:
            return BaseZusCard;
          case CARD_TYPES.CORNER:
            return BaseCornerCard;
          case CARD_TYPES.INDIVIDUAL:
            return BaseIndividualCard;
          default:
            return BaseTeamCard;
        }
      });

      onMounted(async () => {
        try {
          configs.value = await getAllBoardConfigs();
        } catch {
          errorToast('Something went wrong. Please reload the page.');
        }
      });

      const previewBoardConfig = (boardIndex: string) => {
        // @ts-ignore
        boardPreviewed.value = configs.value[boardIndex].config;
        boardPreviewVisible.value = true;
      };

      const updateColorInEditedCard = (color: string) => {
        if (!color) {
          return;
        }

        return cardEdited.value ? (cardEdited.value.color = color) : null;
      };

      const editCard = (id: number, boardIndex: number) => {
        // @ts-ignore
        cardEdited.value = configs.value[boardIndex].config.find((c: Card) => c.id === id);
        boardChangedActiveIndex.value = boardIndex;
      };

      const previewCard = (id: number, boardIndex: number) => {
        // @ts-ignore
        cardPreviewed.value = configs.value[boardIndex].config.find((c: Card) => c.id === id);
        cardPreviewVisible.value = true;
        boardChangedActiveIndex.value = boardIndex;
      };

      const getAllBoardConfigs = async () => {
        return await db
          .collection('boardConfigs')
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push({ id: doc.id, ...doc.data() });
            });

            return resultArray;
          })
          .catch(e => {
            errorToast(e);
          });
      };

      const handleSaveBoard = async (configIndex: number, configId: string) => {
        if (!configs.value[configIndex].upgradeConfig) {
          return;
        }
        try {
          await db.doc(`boardConfigs/${configId}`).update({
            // @ts-ignore
            config: configs.value[configIndex].config,
            upgradeConfig: configs.value[configIndex].upgradeConfig
          });
          successToast(t('adminPanel.businessGame.boardConfig.boardUpdated'));
        } catch (e) {
          errorToast(e);
        }
      };

      const addNewBoardConfig = async () => {
        console.log(configs.value);
        // try {
        //   await db.doc(`boardConfigs/automobile`).update({
        //     // @ts-ignore
        //     config: configs.value.find(c => c.id === 'automobile').config,
        //     upgradeConfig: upgradeConfig.value
        //   });
        //   successToast(t('adminPanel.businessGame.boardConfig.boardUpdated'));
        // } catch (e) {
        //   errorToast(e);
        // }
        // try {
        //   await db.doc(`boardConfigs/fintech`).update({
        //     // @ts-ignore
        //     config: configs.value.find(c => c.id === 'fintech').config,
        //     upgradeConfig: upgradeConfig.value
        //   });
        //   successToast(t('adminPanel.businessGame.boardConfig.boardUpdated'));
        // } catch (e) {
        //   errorToast(e);
        // }
        // try {
        //   await db.doc(`boardConfigs/law`).update({
        //     // @ts-ignore
        //     config: configs.value.find(c => c.id === 'law').config,
        //     upgradeConfig: upgradeConfig.value
        //   });
        //   successToast(t('adminPanel.businessGame.boardConfig.boardUpdated'));
        // } catch (e) {
        //   errorToast(e);
        // }
        // try {
        //   await db.doc(`boardConfigs/startup`).update({
        //     // @ts-ignore
        //     config: configs.value.find(c => c.id === 'startup').config,
        //     upgradeConfig: upgradeConfig.value
        //   });
        //   successToast(t('adminPanel.businessGame.boardConfig.boardUpdated'));
        // } catch (e) {
        //   errorToast(e);
        // }
        // try {
        //   await db.doc(`boardConfigs/future`).update({
        //     // @ts-ignore
        //     config: configs.value.find(c => c.id === 'future').config,
        //     upgradeConfig: upgradeConfig.value
        //   });
        //   successToast(t('adminPanel.businessGame.boardConfig.boardUpdated'));
        // } catch (e) {
        //   errorToast(e);
        // }
      };

      return {
        configs,
        upgradeConfig,
        boardCardComponent,
        cardPreviewed,
        cardPreviewVisible,
        cardEdited,
        boardPreviewVisible,
        boardPreviewed,
        boardChangedActiveIndex,
        godModeNewBoardConfig,
        previewBoardConfig,
        updateColorInEditedCard,
        editCard,
        previewCard,
        handleSaveBoard,
        addNewBoardConfig,
        BUYABLE
      };
    }
  });
