
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import useToast from '@/composables/use-toast';
  import { GAME_NAMES, GAME_NAMES_HUMANIZED } from '@/helpers';
  import { format } from 'date-fns';
  import { enGB, pl } from 'date-fns/locale';
  import { useI18n } from 'vue-i18n';

  const db = firebase.firestore();

  import { FUN_TEAM_CLUES_WORDS } from '@/constants/words';

  export default defineComponent({
    name: 'SuperUserCompaniesList',
    setup() {
      const store = useStore();
      const loading = ref(false);
      const accessLoading = ref(false);
      const { errorToast } = useToast();
      const { locale } = useI18n();

      const allCompanies = computed(() => store.state.allCompanies);

      const formatDate = (date: any) => {
        return format(date.seconds ? date.seconds : new Date(date), 'dd MMM yyyy, HH:mm', {
          locale: locale.value === 'pl' ? pl : enGB
        });
      };

      onMounted(async () => {
        loading.value = true;
        accessLoading.value = true;
        try {
          const companies = await getAllCompanies();
          store.commit('setAllCompanies', companies);
          // await checkIfUserIsAdmin(companyId);
        } catch {
          errorToast('Something went wrong. Please reload the page.');
        }
        loading.value = false;
      });

      const getAllCompanies = async () => {
        return await db
          .collection('companies')
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push({ id: doc.id, ...doc.data() });
            });

            return resultArray;
          })
          .catch(e => {
            errorToast(e);
          });
      };

      const updateAllCompaniesTest = async () => {
        for (const company of allCompanies.value) {
          console.log(company.id, company.package, company.funTeamCluesSettings);
          await db.doc(`companies/${company.id}`).update({
            funTeamCluesSettings: {
              timeForGuessingInSeconds: 60,
              timeForGivingClueInSeconds: 60
            },
            package: company.package
              ? {
                  ...company.package,
                  gamesInPackage: {
                    FUN_TEAM_BIDDING: 1000,
                    FUN_BUSINESS_GAME: 1000,
                    FUN_ICE_BREAKER: 1000,
                    FUN_TEAM_CLUES: 1000
                  },
                  gamesLeft: {
                    FUN_TEAM_BIDDING: 1000,
                    FUN_BUSINESS_GAME: 1000,
                    FUN_ICE_BREAKER: 1000,
                    FUN_TEAM_CLUES: 1000
                  }
                }
              : null
          });
        }
      };

      const updateWords = async () => {
        await db.doc(`funTeamCluesWords/pl`).set({ words: FUN_TEAM_CLUES_WORDS.pl });
      };
      //
      // const testFnBoards = async () => {
      //   for (let [index, category] of [
      //     'automobile',
      //     'default',
      //     'startup'
      //   ].entries()) {
      //     await db.doc(`boardConfigs/${category}`).set({
      //       config: prodBoardConfigs[index].config,
      //       upgradeConfig: prodBoardConfigs[index].upgradeConfig
      //     });
      //   }
      // };

      return {
        loading,
        accessLoading,
        allCompanies,
        GAME_NAMES,
        GAME_NAMES_HUMANIZED,
        formatDate,
        updateAllCompaniesTest,
        updateWords
        // testFnBoards
      };
    }
  });
