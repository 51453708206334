import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "superuser-started-games flex p3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, {
      value: _ctx.startedGames,
      responsiveLayout: "scroll"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "companyId",
          header: "Company ID"
        }),
        _createVNode(_component_Column, {
          field: "companyName",
          header: "Company Name"
        }),
        _createVNode(_component_Column, {
          field: "gameId",
          header: "Game ID"
        }),
        _createVNode(_component_Column, {
          field: "gameName",
          header: "Game Name"
        }),
        _createVNode(_component_Column, {
          field: "playerEmail",
          header: "Player Email"
        }),
        _createVNode(_component_Column, {
          field: "date",
          header: "Date"
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.createdOn &&
            _ctx.format(new Date(slotProps.data.createdOn), 'dd MMM yyyy, HH:mm')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}