import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "superuser-ind-referrals flex p3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, {
      value: _ctx.referrals,
      responsiveLayout: "scroll"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "email",
          header: "Email"
        }),
        _createVNode(_component_Column, {
          field: "code",
          header: "Code"
        }),
        _createVNode(_component_Column, {
          field: "name",
          header: "Name"
        }),
        _createVNode(_component_Column, {
          field: "surname",
          header: "Surname"
        })
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}