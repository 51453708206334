import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "superuser-ftb-questions flex flex-column p3" }
const _hoisted_2 = { class: "flex items-center mb2" }
const _hoisted_3 = { class: "mr2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DropdownSelect = _resolveComponent("DropdownSelect")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.questions, (questionCategory) => {
      return (_openBlock(), _createBlock("div", {
        key: questionCategory.id,
        class: "mb4"
      }, [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString(questionCategory.id), 1),
          _createVNode("button", {
            onClick: ($event: any) => (_ctx.addNew(questionCategory.id))
          }, "Add new in " + _toDisplayString(questionCategory.id), 9, ["onClick"]),
          _createVNode("button", {
            onClick: ($event: any) => (_ctx.handleSaveQuestions(questionCategory.list, questionCategory.id))
          }, " Save " + _toDisplayString(questionCategory.id) + " questions ", 9, ["onClick"])
        ]),
        _createVNode(_component_DataTable, {
          value: questionCategory.list,
          responsiveLayout: "scroll",
          editMode: "cell"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "id",
              header: _ctx.$t('gamesCommon.id')
            }, {
              editor: _withCtx((slotProps) => [
                _withDirectives(_createVNode("input", {
                  "onUpdate:modelValue": ($event: any) => (slotProps.data[slotProps.column.props.field] = $event)
                }, null, 8, ["onUpdate:modelValue"]), [
                  [_vModelText, slotProps.data[slotProps.column.props.field]]
                ])
              ]),
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(slotProps.data.id), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "textEn",
              header: 'textEn'
            }, {
              editor: _withCtx((slotProps) => [
                _withDirectives(_createVNode("input", {
                  "onUpdate:modelValue": ($event: any) => (slotProps.data[slotProps.column.props.field] = $event)
                }, null, 8, ["onUpdate:modelValue"]), [
                  [_vModelText, slotProps.data[slotProps.column.props.field]]
                ])
              ]),
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(slotProps.data.textEn), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "textPl",
              header: 'textPl'
            }, {
              editor: _withCtx((slotProps) => [
                _withDirectives(_createVNode("input", {
                  "onUpdate:modelValue": ($event: any) => (slotProps.data[slotProps.column.props.field] = $event)
                }, null, 8, ["onUpdate:modelValue"]), [
                  [_vModelText, slotProps.data[slotProps.column.props.field]]
                ])
              ]),
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(slotProps.data.textPl), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "type",
              header: _ctx.$t('gamesCommon.type')
            }, {
              editor: _withCtx((slotProps) => [
                _createVNode(_component_DropdownSelect, {
                  modelValue: slotProps.data.type,
                  "onUpdate:modelValue": ($event: any) => (slotProps.data.type = $event),
                  options: _ctx.typeOptions,
                  optionLabel: "label",
                  optionValue: "value"
                }, {
                  option: _withCtx((slotProps) => [
                    _createVNode("span", null, _toDisplayString(slotProps.option.label), 1)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "options"])
              ]),
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(slotProps.data.type === 'multiple'
                ? _ctx.$t(`funTeamBidding.customQuestions.multiple`)
                : _ctx.$t(`funTeamBidding.customQuestions.yesNo`)), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "options",
              header: _ctx.$t('gamesCommon.options')
            }, {
              editor: _withCtx((slotProps) => [
                _withDirectives(_createVNode("input", {
                  "onUpdate:modelValue": ($event: any) => (slotProps.data[slotProps.column.props.field][0] = $event)
                }, null, 8, ["onUpdate:modelValue"]), [
                  [_vModelText, slotProps.data[slotProps.column.props.field][0]]
                ]),
                _withDirectives(_createVNode("input", {
                  "onUpdate:modelValue": ($event: any) => (slotProps.data[slotProps.column.props.field][1] = $event)
                }, null, 8, ["onUpdate:modelValue"]), [
                  [_vModelText, slotProps.data[slotProps.column.props.field][1]]
                ]),
                _withDirectives(_createVNode("input", {
                  "onUpdate:modelValue": ($event: any) => (slotProps.data[slotProps.column.props.field][2] = $event)
                }, null, 8, ["onUpdate:modelValue"]), [
                  [_vModelText, slotProps.data[slotProps.column.props.field][2]]
                ]),
                _withDirectives(_createVNode("input", {
                  "onUpdate:modelValue": ($event: any) => (slotProps.data[slotProps.column.props.field][3] = $event)
                }, null, 8, ["onUpdate:modelValue"]), [
                  [_vModelText, slotProps.data[slotProps.column.props.field][3]]
                ])
              ]),
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(slotProps.data.options), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "validAnswer",
              header: _ctx.$t('gamesCommon.validAnswer')
            }, {
              editor: _withCtx((slotProps) => [
                _createVNode(_component_DropdownSelect, {
                  modelValue: slotProps.data.validAnswer,
                  "onUpdate:modelValue": ($event: any) => (slotProps.data.validAnswer = $event),
                  options: 
                slotProps.data.options.map(item => ({
                  label: item,
                  value: item
                }))
              ,
                  "option-label": "label",
                  "option-value": "value"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
              ]),
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(slotProps.data.validAnswer), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "actions",
              header: _ctx.$t('gamesCommon.actions')
            }, {
              body: _withCtx((slotProps) => [
                _createVNode("i", {
                  class: "pi pi-trash",
                  onClick: ($event: any) => (_ctx.handleDeleteQuestionAndSave(slotProps.data.id))
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["header"])
          ]),
          _: 2
        }, 1032, ["value"])
      ]))
    }), 128))
  ]))
}