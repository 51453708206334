
  import { defineComponent, onMounted, ref } from 'vue';
  import { format } from 'date-fns';
  import firebase from 'firebase';
  import useToast from '@/composables/use-toast';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'SuperUserFeedbackFromAdminPanel',
    setup() {
      const feedback = ref([]);
      const { errorToast } = useToast();

      onMounted(async () => {
        try {
          feedback.value = await getAllFeedback();
        } catch {
          errorToast('Something went wrong. Please reload the page.');
        }
      });

      const getAllFeedback = async () => {
        return await db
          .collection('feedbackFromAdminPanel')
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push({ id: doc.id, ...doc.data() });
            });

            return resultArray;
          })
          .catch(e => {
            errorToast(e);
          });
      };

      return { feedback, format };
    }
  });
