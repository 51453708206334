import { createVNode as _createVNode, resolveComponent as _resolveComponent, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "superuser-ftb-questions flex flex-column p3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DropdownSelect = _resolveComponent("DropdownSelect")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", null, [
      _createVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addNew && _ctx.addNew(...args)))
      }, "Add new"),
      _createVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleSaveQuestions && _ctx.handleSaveQuestions(...args)))
      }, "Save questions")
    ]),
    _createVNode(_component_DataTable, {
      value: _ctx.questions.list,
      responsiveLayout: "scroll",
      editMode: "cell"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "id",
          header: _ctx.$t('gamesCommon.id')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "textEn",
          header: "Text (English)"
        }, {
          editor: _withCtx((slotProps) => [
            _withDirectives(_createVNode("input", {
              "onUpdate:modelValue": ($event: any) => (slotProps.data[slotProps.column.props.field] = $event)
            }, null, 8, ["onUpdate:modelValue"]), [
              [_vModelText, slotProps.data[slotProps.column.props.field]]
            ])
          ]),
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.textEn), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "textPl",
          header: "Text (Polish)"
        }, {
          editor: _withCtx((slotProps) => [
            _withDirectives(_createVNode("input", {
              "onUpdate:modelValue": ($event: any) => (slotProps.data[slotProps.column.props.field] = $event)
            }, null, 8, ["onUpdate:modelValue"]), [
              [_vModelText, slotProps.data[slotProps.column.props.field]]
            ])
          ]),
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.textPl), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "type",
          header: _ctx.$t('gamesCommon.type')
        }, {
          editor: _withCtx((slotProps) => [
            _createVNode(_component_DropdownSelect, {
              modelValue: slotProps.data.type,
              "onUpdate:modelValue": ($event: any) => (slotProps.data.type = $event),
              options: _ctx.typeOptions,
              optionLabel: "label",
              optionValue: "value"
            }, {
              option: _withCtx((slotProps) => [
                _createVNode("span", null, _toDisplayString(slotProps.option.label), 1)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "options"])
          ]),
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.type === _ctx.FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE
              ? _ctx.$t(`funIceBreaker.customQuestions.types.${_ctx.FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE}`)
              : _ctx.$t(`funIceBreaker.customQuestions.types.${_ctx.FUN_ICE_BREAKER_QUESTION_TYPES.TRUTHLIE}`)), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "actions",
          header: _ctx.$t('gamesCommon.actions')
        }, {
          body: _withCtx((slotProps) => [
            _createVNode("i", {
              class: "pi pi-trash",
              onClick: ($event: any) => (_ctx.handleDeleteQuestionAndSave(slotProps.data.id))
            }, null, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}