
  import { shallowReactive, toRefs, defineComponent, ref } from 'vue';
  import { useI18n } from 'vue-i18n';

  import { BOARD_CONFIGS_TEMPLATES } from '@/constants';
  import useToast from '@/composables/use-toast';
  import COUNTRIES from '@/countries';

  export default defineComponent({
    name: 'RegisterFormOnly',
    emits: ['submit'],
    props: {
      loadingText: {
        type: String,
        default: ''
      }
    },
    setup(props, { emit }) {
      const state = shallowReactive({
        email: '',
        password: '',
        confirmPassword: '',
        companyName: '',
        referralCode: '',
        companyAddress: '',
        taxId: '',
        zipCode: '',
        city: '',
        country: '',
        boardTemplates: BOARD_CONFIGS_TEMPLATES
      });
      const { errorToast } = useToast();
      const { t } = useI18n();

      const boardTemplate = ref(null);
      const invoiceDataOpen = ref(false);

      const openInvoiceData = () => {
        invoiceDataOpen.value = true;
      };
      const hideInvoiceData = () => {
        invoiceDataOpen.value = false;
        state.companyAddress = '';
        state.taxId = '';
        state.zipCode = '';
        state.city = '';
        state.country = '';
      };

      const handleRegister = () => {
        if (state.password !== state.confirmPassword) {
          errorToast(t('adminPanel.registerForm.passwordDontMatch'));
          return;
        }

        if (!(state.companyName && state.email && state.password && boardTemplate.value)) {
          errorToast(t('adminPanel.registerForm.fillRequired'));
          return;
        }

        if (
          invoiceDataOpen.value &&
          !(state.companyAddress && state.taxId && state.zipCode && state.city && state.country)
        ) {
          errorToast(t('adminPanel.registerForm.fillRequiredForInvoice'));
          return;
        }

        emit('submit', {
          companyName: state.companyName,
          referralCode: state.referralCode,
          email: state.email,
          password: state.password,
          boardTemplate: boardTemplate.value,
          // chance template is the same as board template, to help users not make mistake
          chanceTemplate: boardTemplate.value,
          invoiceData: {
            companyAddress: state.companyAddress,
            taxId: state.taxId,
            zipCode: state.zipCode,
            city: state.city,
            country: state.country
          }
        });
      };

      return {
        boardTemplate,
        ...toRefs(state),
        handleRegister,
        openInvoiceData,
        hideInvoiceData,
        invoiceDataOpen,
        t,
        COUNTRIES
      };
    }
  });
