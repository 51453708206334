<template>
  <div class="p3 superuser-company-view">{{ company.id }} {{ company.name }}</div>
</template>

<script>
  export default {
    name: 'SuperUserCompanyView',
    computed: {
      company() {
        return this.$store.state.allCompanies.find(c => c.id === this.$route.params.id);
      }
    }
  };
</script>

<style scoped lang="scss"></style>
