<template>
  <div class="superuser-navigation flex flex-column items-center justify-center">
    <router-link class="item" :to="'/superuser-panel'">Companies</router-link>
    <router-link class="item" :to="'/superuser-panel/referrals'">Individual Referrals</router-link>
    <router-link class="item" :to="'/superuser-panel/discounts'">Discounts</router-link>
    <router-link class="item" :to="'/superuser-panel/funbusinessgame-boards'">
      FunBusinessGame Boards
    </router-link>
    <router-link class="item" :to="'/superuser-panel/funbusinessgame-chance'">
      FunBusinessGame Chance Cards
    </router-link>
    <router-link class="item" :to="'/superuser-panel/funteambidding-custom-questions'">
      FunTeamBidding Custom Questions
    </router-link>
    <router-link class="item" :to="'/superuser-panel/funicebreaker-custom-questions'">
      FunIceBreaker Custom Questions
    </router-link>
    <router-link class="item" :to="'/superuser-panel/create-company'">Create Company</router-link>
    <router-link class="item" :to="'/superuser-panel/feedback'">Feedback</router-link>
    <router-link class="item" :to="'/superuser-panel/feedback-admin-panel'">
      Feedback From Admin Panel
    </router-link>
    <router-link class="item" :to="'/superuser-panel/started-games'">Started Games</router-link>
  </div>
</template>

<script>
  export default {
    name: 'SuperUserNavigation'
  };
</script>

<style scoped lang="scss">
  .superuser-navigation {
    width: 120px;
    background: color(primary);

    .item {
      padding: 12px 6px;
      @include font(11px, 600);
      color: white;
      text-align: center;
    }
  }
</style>
