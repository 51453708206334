
  import { defineComponent, onMounted, computed } from 'vue';
  import SuperUserNavigation from '@/components/superuser/SuperUserNavigation.vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  export default defineComponent({
    name: 'SuperUserPanel',
    components: { SuperUserNavigation },
    setup() {
      const store = useStore();
      const { push } = useRouter();

      const user = computed(() => store.state.user);

      onMounted(async () => {
        if (user.value.email !== 'biley94@gmail.com') {
          push('/login');
          return;
        }
      });
      //
      // const checkIfUserIsAdmin = async (companyId: string) => {
      //   await db
      //     .doc(`companies/${companyId}/users/${store.state.user.email}`)
      //     .get()
      //     .then(doc => {
      //       const data = doc.data();
      //
      //       if (!data?.roles.admin) {
      //         throw new Error();
      //       }
      //     })
      //     .catch(() => {
      //       push('/');
      //       errorToast('You do not have permissions to access this page');
      //     });
      // };
    }
  });
